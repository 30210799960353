window.Popper = require('popper.js').default;
window.$ = window.jQuery = require('jquery');
import Echo from "laravel-echo"

require('bootstrap');
window.bootstrap = require('bootstrap/dist/js/bootstrap.bundle.js');
window.moment = require('moment');
require('moment/dist/locale/pt-br')
window.Cookies = require('js-cookie');
require('datatables.net-bs5');
require('datatables.net-buttons-bs5');
require('datatables.net-buttons/js/buttons.colVis.js');
require('datatables.net-buttons/js/buttons.html5.js');
require('datatables.net-fixedheader-bs5');
require('datatables.net-responsive-bs5');
require('datatables.net-rowgroup-bs5');
require('datatables.net-select-bs5');
require('datatables.net-rowreorder-bs5');
require('select2');
require('select2/dist/js/i18n/pt-BR');
require('daterangepicker')
require('jquery-slimscroll')
require('jquery-contextmenu')
require('metismenu')
require("file-saver/dist/FileSaver.min")
require("xlsx/dist/xlsx.core.min")
require("gasparesganga-jquery-loading-overlay")
require("bootstrap-datepicker")
require("bootstrap-datepicker/dist/locales/bootstrap-datepicker.pt-BR.min.js")
window.toastr = require('toastr');
window.SensorAFK = require('sensor-afk');
require("./taxireis")
require("./modal")
require("./settings")
window.Pusher = require('pusher-js');


window.Echo = new Echo({
  
    csrfToken: $('meta[name="csrf-token"]').attr('content'),
	broadcaster: 'pusher',
    key: '9155881b349a07d75eb2',
    wsHost: window.location.hostname,
    wsPort: 46375,
	wssPort: 443 + "/ws",
    forceTLS: true,
    disableStats: true,

});
 