(function ($) {
    "use strict"

    $.fn.modal = function (action) {
        switch (action) {
            case "alert":
                alert("teste");
                break;
            case "show":
                bootstrap.Modal.getInstance(document.getElementById(this.attr("id"))).show();
                break;
            case "hide":
                bootstrap.Modal.getInstance(document.getElementById(this.attr("id"))).hide();
                break;
            case "dispose":

                let current_modal = document.getElementById(this.attr("id"));
                bootstrap.Modal.getInstance(current_modal).hide();
                $(this.attr("id")).remove()
                break;
        }

    }

}(jQuery));

function Modals(props) {
    this.props = {
        title: "", // the dialog title html
        body: "", // the dialog body html
        footer: "", // the dialog footer html (mainly used for buttons)
        modalClass: "fade", // Additional css for ".modal", "fade" for fade effect
        modalDialogClass: "", // Additional css for ".modal-dialog", like "modal-lg" or "modal-sm" for sizing
        options: null, // The Bootstrap modal options as described here: https://getbootstrap.com/docs/4.0/components/modal/#options
        // Events:
        onCreate: null, // Callback, called after the modal was created
        onShown: null, // Callback, called after the modal was shown and completely faded in
        onDispose: null, // Callback, called after the modal was disposed
        onSubmit: null, // Callback of $.showConfirm(), called after yes or no was pressed
        isAlert: false,
        parent: null,
        size: "sm"
    }
    for (const prop in props) {
        // noinspection JSUnfilteredForInLoop
        this.props[prop] = props[prop]
    }
    this.id = "bootstrap-show-modal-" + document.getElementsByClassName("modal").length
    const self = this;
    this.element = document.createElement("div")
    this.element.id = this.id
    this.element.setAttribute("class", "modal " + this.props.modalClass)
    this.element.setAttribute("tabindex", "-1");
    this.element.setAttribute("data-bs-backdrop", "static")
    this.element.setAttribute("aria-hidden", "true")
    this.element.innerHTML = '  <div class="modal-dialog modal-' + this.props.size + ' modal-dialog-centered" >' +
        '    <div class="modal-content">' +
        '      <div class="modal-header">' +
        '        <h5 class="modal-title"></h5>' +
        '        <div>' +
        '           <button id="verLogsBTN" type="button" class="btn btn-primary" aria-label="Logs">Ver Logs</button>' +
        '           <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>' +
        '        </div>' +
        '      </div>' +
        '      <div class="modal-body">' +
        '        <p></p>' +
        '      </div>' +
        '      <div class="modal-footer">' +
        '      </div>' +
        '    </div>' +
        '  </div>'

    document.body.appendChild(this.element)
    if (this.props.options) {
        this.modalel = new bootstrap.Modal(this.element, this.props.options);
    } else {
        this.modalel = new bootstrap.Modal(this.element);
    }

    bootstrap.Modal.prototype.getModalParent = function () {
        return bootstrap.Modal.getInstance(document.getElementById(self.props.parent));
    }
    this.titleElement = this.element.querySelector(".modal-title")
    this.bodyElement = this.element.querySelector(".modal-body")
    this.footerElement = this.element.querySelector(".modal-footer")

    if (this.props.title) {
        this.titleElement.innerHTML = this.props.title
    }
    if (this.props.body) {
        this.bodyElement.innerHTML = this.props.body
    }
    if (this.props.footer) {
        this.footerElement.innerHTML = this.props.footer
    }
    let parent = null;
    let current_modal = document.getElementById(self.id);
    if (self.props.parent != null) {
        parent = bootstrap.Modal.getInstance(document.getElementById(self.props.parent)).getModalParent();
    }

    this.element.addEventListener('shown.bs.modal', function () {
        if (self.props.onShown) {
            self.props.onShown(self)
        }
        let log_view = $(current_modal).find("#logs_view");
        if(log_view.find(".card").length >  0) {
            $(current_modal).find("#verLogsBTN").on("click",function() {
                log_view.is(":visible") ? log_view.fadeOut('fast') : log_view.fadeIn('fast',function() { log_view.css('display', 'flex'); });
            })

        } else {
            $(current_modal).find("#verLogsBTN").remove();


        }

        if (parent != null) {
            document.getElementById(self.props.parent).setAttribute("parent", self.id);

            parent.hide();
        }
    })

    this.element.addEventListener("hidePrevented.bs.modal", function (e) {
        if (current_modal.getAttribute("isselectopen") !== "true") {
            if (current_modal.getAttribute('hasChanged') === "true") {
                modals.confirm({
                    title: "Você tem certeza que deseja fechar?",
                    body: "Deseja fechar sem salvar?",
                    textTrue: "Sim",
                    textFalse: "Não",

                    onSubmit: function (result) { // callback on confirm
                        if (result) {
                            self.modalel.hide();
                        }
                    },
                })
            } else {
                self.modalel.hide();
            }
        }
    })

    let buttons = this.element.querySelectorAll('[data-bs-dismiss]');
    for (let i = 0; i < buttons.length; i++) {
        buttons[i].addEventListener("click", function (event) {
            event.preventDefault()
            self.modalel.hide();
            current_modal.remove();
        })
    }


    this.element.addEventListener('hidden.bs.modal', function (event) {
        if (self.props.onHidden) {
            self.props.onHidden(self)
        }

        if (parent != null) {
            parent.show();
            document.getElementById(self.props.parent).removeAttribute("parent");
            current_modal.remove();

        } else {
            if (document.getElementById(self.id).getAttribute("parent") === null) {
                current_modal.remove();
            }
        }

    })

    if (this.props.onCreate) {
        this.props.onCreate(this)
        let inputs = self.element.getElementsByTagName('input');
        let selects = self.element.getElementsByTagName('select')
        for (let i = 0; i < inputs.length; i++) {
            inputs[i].addEventListener('change', function (event) {
                self.element.setAttribute('hasChanged', "true")
            })
        }
        for (let i = 0; i < selects.length; i++) {
            selects[i].addEventListener('change', function (event) {
                self.element.setAttribute('hasChanged', "true")
            })
        }

    }

    if (self.modalel) {
        self.modalel.show();
    }

}

window.modals = {
    modal: function (props) {
        if (props.buttons) {
            let footer = "";
            for (var key in props.buttons) {
                // noinspection JSUnfilteredForInLoop
                var buttonText = props.buttons[key]
                footer += '<button type="button" class="btn btn-primary" data-value="' + key + '" data-bs-dismiss="modal">' + buttonText + '</button>'
            }
            props.footer = footer
        }

        return new Modals(props);
    },
    alerta: function (props) {
        if (!props.buttons)
            props.buttons = {OK: 'OK'}
        props.isAlert = true

        return this.modal(props)
    },
    confirm: function (props) {
        props.footer = '<button data-bs-dismiss="modal"  class="btn btn-danger btn-false btn-cancel">' + props.textFalse + '</button><button data-bs-dismiss="modal" class="btn btn-success btn-true">' + props.textTrue + '</button>'
        props.onCreate = function (modal) {
            let buttons = document.getElementById(modal.id).getElementsByClassName("btn");
            for (let i = 0; i < buttons.length; i++) {
                buttons[i].addEventListener("click", function (event) {
                    event.preventDefault()
                    modal.props.onSubmit(event.target.getAttribute("class").indexOf("btn-true") !== -1, modal)
                })
            }

        }
        return this.modal(props)
    }
}

