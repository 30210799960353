(function ($) {
    window.TaxiReis = {
        cidade: [],
        DefaultValues: null,
        functions: {
            core: {
                getURL: function (path = null) {
                    const getUrl = window.location;
                    let url = getUrl.protocol + "//" + getUrl.host
                    if (path !== null) {
                        url = url + path;
                    }
                    return url;
                }
            },
            mudarSenha: function () {
                modals.modal({
                    title: "Mudar Senha",
                    footer: '<button type="button" class="btn btn-danger" data-bs-dismiss="modal">Cancelar</button><button type="submit" class="btn btn-success">Atualizar</button>',
                    body: "  <div class=\"mb-3\">\n" +
                        "    <label for=\"current_password\" class=\"form-label\">Senha</label>\n" +
                        "    <input type=\"password\" class=\"form-control\" id=\"current_password\">\n" +
                        "  </div>\n" +
                        "  <div class=\"mb-3\">\n" +
                        "    <label for=\"new_password\" class=\"form-label\">Nova Senha</label>\n" +
                        "    <input type=\"password\" class=\"form-control\" id=\"new_password\">\n" +
                        "  </div>\n" +
                        "  <div class=\"mb-3\">\n" +
                        "    <label for=\"new_confirm_password\" class=\"form-label\">Nova Senha</label>\n" +
                        "    <input type=\"password\" class=\"form-control\" id=\"new_confirm_password\">\n" +
                        "  </div>\n",
                    onCreate: function (e) {
                        $(e.element).on("click", "button[type='submit']", function (a) {
                            $.ajax({
                                type: "POST",
                                dataType: "JSON",
                                data: {
                                    "current_password": $(e.element).find("#current_password").val(),
                                    "new_password": $(e.element).find("#new_password").val(),
                                    "new_password_confirm": $(e.element).find("#new_password_confirm").val(),
                                },
                                url: "/mudar-senha",
                                success: function (data) {
                                    $(".modal-backdrop").remove();
                                    $('body').removeClass('modal-open');
                                    toastr.success(data.message)
                                    $(e.element).modal('hide');
                                },
                                error: function (data) {
                                    const response = data.responseJSON;
                                    if (typeof response.errors !== 'undefined' && Object.keys(response.errors).length > 0) {
                                        $.each(response.errors, function (k, v) {
                                            if (v.length > 0) {
                                                $.each(v, function (key, value) {
                                                    toastr.error(value);
                                                })
                                            } else {
                                                toastr.error(v)
                                            }
                                        })
                                        TaxiReis.MaskAll();
                                    } else {
                                        if (typeof response.message !== 'undefined') {
                                            toastr.error(response.message)
                                        } else {
                                            toastr.error(response)
                                        }
                                    }
                                }
                            });
                        })
                    }
                })
            },
            tabela: {
                ExpandirTodas() {
                    const trs = $("table tbody").find("tr");
                    trs.each(function (key, value) {
                        const tr = $(value);
                        if (!tr.hasClass("parent")) {
                            tr.find("td")[0].click()
                        }
                    })
                }
            },
            faturas: {
                unificar: function () {
                    modals.modal({
                        title: "Unificar solicitação com fatura",
                        body: "<form id='form_unificar'>" +
                            "<div class=\"mb-3\">" +
                            "<label>Fatura</label>" +
                            "<select class=\"form-control\" name=\"cod_fatura\" id=\"cod_fatura\"></select>" +
                            "</div>" +
                            "</form>",
                        size: "md",
                        footer: '<button type="button" class="btn btn-danger" data-bs-dismiss="modal">Cancelar</button><button type="submit" class="btn btn-success" id="this.id">Unificar</button>',
                        onCreate: function (modal) {
                            LaravelDataTables["crud-table"].rows({
                                selected: true
                            }).data().toArray().map(item => {
                                return item.cod_fatura
                            }).forEach((v, k) => {

                                $(modal.element).find('#cod_fatura').append($('<option>', {
                                    value: v,
                                    text: v
                                }));
                            })
                            $(modal.element).on("click", "button[type='submit']", function (event) {
                                $.LoadingOverlay("show");

                                $.ajax({
                                    type: "POST",
                                    dataType: "JSON",
                                    data: {
                                        cod_fatura: $(modal.element).find("#cod_fatura").val(),
                                        cod_solicitacao: LaravelDataTables["crud-table"].rows({ selected: true }).data().toArray().map(item => item.id)
                                    },
                                    url: TaxiReis.functions.core.getURL() + "/api/v2/solicitacoes/unificar",
                                    success: (data) => {
                                        if (!data.error) {
                                            toastr["success"]("Solicitações unificadas com a fatura com sucesso");
                                            $(e.element).modal("hide");
                                        } else
                                            toastr["error"]("Erro: " + data);
                                        $.each(window.LaravelDataTables, function (k, v) {
                                            v.ajax.reload();
                                        })

                                    },
                                    error: (response) => {
                                        toastr.error("ocorreu um erro ...")
                                        $.LoadingOverlay("hide");
                                    },
                                    complete: (response) => {
                                        $.LoadingOverlay("hide");
                                    }

                                })
                            });
                        }
                    });
                },
                cancelar: function (id) {
                    let cur_ir = id;
                    modals.confirm({
                        title: "Você realmente deseja cancelar essa fatura?",
                        body: "Realmente gostaria de cancelar a fatura #" + cur_ir + "?",
                        textTrue: "Sim",
                        textFalse: "Não",
                        onSubmit: function (e) {
                            if (e) {
                                $.ajax({
                                    type: "POST",
                                    url: "/api/v2/faturas/cancelar/" + cur_ir,
                                    success: function (data) {
                                        if (!data.error)
                                            toastr["success"]("Status alterado com sucesso");
                                        else
                                            toastr["error"]("Erro: " + data);
                                        $.each(window.LaravelDataTables, function (k, v) {
                                            v.ajax.reload();
                                        })
                                    },
                                    error: function (data) {
                                        toastr["error"]("Erro: " + JSON.stringify(data));
                                    }

                                });
                            }
                        },
                        onDispose: function () {

                        },
                    });
                },
                marcarcomo: function (cod_fatura, cod_status, status_text) {

                    modals.confirm({
                        title: "Você realmente deseja alterar essa fatura?",
                        body: "Realmente gostaria de alterar a fatura #" + cod_fatura + " como " + status_text + "?",
                        textTrue: "Sim",
                        textFalse: "Não",
                        onSubmit: function (e) {
                            if (e) {
                                $.ajax({
                                    type: "PATCH",
                                    url: "/api/v2/faturas/" + cod_fatura,
                                    dataType: "json",
                                    data: { cod_fs: parseInt(cod_status) },
                                    success: function (data) {
                                        if (!data.error)
                                            toastr["success"]("Status alterado com sucesso");
                                        else
                                            toastr["error"]("Erro: " + data);
                                        $.each(window.LaravelDataTables, function (k, v) {
                                            v.ajax.reload();
                                        })
                                    },
                                    error: function (data) {
                                        toastr["error"]("Erro: " + JSON.stringify(data));
                                    }

                                });
                            }
                        },
                        onDispose: function () {

                        },
                    });
                },
                adicionar: function (id) {
                    if (!id)
                        return
                    let cur_id = id;
                    $.LoadingOverlay("show"),
                        $.ajax({
                            type: "GET",
                            url: "/api/v2/forms/faturas/add/" + cur_id,
                            dataType: "json",
                            success: function (t) {
                                let solicitacao = null;
                                let solicitacoes = t.data;
                                if (solicitacoes !== null && solicitacoes) {
                                    for (let i = 0; i < solicitacoes.length; i++) {
                                        if (i === 0) {
                                            solicitacao = solicitacoes[i];
                                            continue;
                                        }
                                        if (typeof solicitacoes[i]["itinerarios"] !== "undefined" && solicitacoes[i]["itinerarios"] !== null && typeof solicitacoes[i]["itinerarios"]["valor"] !== "undefined" && solicitacoes[i]["itinerarios"]["valor"] !== null) {
                                            if (solicitacoes[i]["valor_itinerario"] > solicitacao["valor_itinerario"]) {
                                                solicitacao = solicitacoes[i];
                                            }
                                        } else {
                                            if (typeof solicitacao[i]["fatura"] !== "undefined" && solicitacao[i]["fatura"] !== null) {

                                            }
                                        }
                                    }
                                }
                                modals.modal({
                                    body: t.html,
                                    size: "xl",
                                    footer: '<button type="button" class="btn btn-danger" data-bs-dismiss="modal">Cancelar</button><button type="submit" class="btn btn-success">Adicionar</button>',
                                    onCreate: function (e) {
                                        const calcularTotal = function () {
                                            const formatter = new Intl.NumberFormat("pt-br", {
                                                style: "currency",
                                                currency: "BRL"
                                            });

                                            let inputs = $(e.element).find("#km_extras,#horas_paradas,#despesas_adicionais,#estacionamento,#pedagio,#barcas");
                                            let val_iti;
                                            try {
                                                val_iti = parseFloat(solicitacao.itinerarios.valor);
                                            } catch (e) {
                                                val_iti = 0;
                                                console.info(e)
                                            }
                                            let value_carro = val_iti;
                                            let value_van = val_iti;
                                            let hasCarro = false;
                                            let hasVan = false;
                                            let value = "";
                                            $.each(inputs, function (k, v) {

                                                if (v.id === "km_extras" || v.id === "horas_paradas") {
                                                    let val_carro = 0;
                                                    let val_van = 0;
                                                    if (typeof v.getAttribute("carro") !== "undefined" && v.getAttribute("carro") !== null && v.getAttribute("carro") !== "") {
                                                        val_carro = parseFloat(v.getAttribute("carro"));
                                                    }
                                                    if (typeof v.getAttribute("van") !== "undefined" && v.getAttribute("van") !== null && v.getAttribute("van") !== "") {
                                                        val_van = parseFloat(v.getAttribute("van"));
                                                    }
                                                    console.info(value_van);
                                                    console.info(value_carro);
                                                    value_carro = parseFloat(value_carro) + val_carro;
                                                    value_van = value_van + val_van;
                                                    hasCarro = v.getAttribute("carro") != null;
                                                    hasVan = v.getAttribute("van") != null;
                                                } else {
                                                    let val = 0;
                                                    let v_value = parseFloat(v.value);
                                                    if (v_value > 0) {

                                                        value_carro = value_carro + v_value;
                                                        value_van = value_van + v_value;
                                                    }
                                                }
                                            })

                                            if (hasCarro && hasVan) {
                                                value = `Carro: ${formatter.format(value_carro)} | Van: ${formatter.format(value_van)}`
                                            } else {
                                                value = hasCarro ? formatter.format(value_carro) : formatter.format(value_van);
                                            }
                                            $(e.element).find("#valor_total").val(value)
                                        };
                                        $(e.element).find("#formAdd").append("<input type='hidden' name='cod_solicitacao' value='" + id + "' id='cod_solicitacao'>");
                                        TaxiReis.MaskAll(),
                                            $("#passageiros_table").DataTable({
                                                responsive: true,
                                                autoWidth: false,
                                                drawCallback: function () {
                                                    let max_pass_carro = 4;
                                                    try {
                                                        max_pass_carro = solicitacao.cliente.max_pass_carro;
                                                    } catch (ex) {
                                                        max_pass_carro = 4;
                                                        console.info(ex)
                                                    }
                                                    if ($(e.element).find("#passageiros_table").dataTable().api().rows().count() > max_pass_carro) {
                                                        $(e.element).find("#cod_tipo_veiculo").val(8).trigger("change")
                                                    }
                                                }
                                            })
                                        $("#solicitacoes_table").DataTable({
                                            data: solicitacoes,
                                            "columns": [
                                                {
                                                    data: "n_chamado",
                                                    name: "n_chamado",
                                                    title: "Chamado",
                                                    orderable: true,
                                                    searchable: true,
                                                    responsivePriority: 1
                                                },
                                                {
                                                    data: "data_programada",
                                                    name: "data_programada",
                                                    title: "Data Programada",
                                                    orderable: true,
                                                    searchable: false,
                                                    responsivePriority: 1
                                                },
                                                {
                                                    data: "cliente.nome_fantasia",
                                                    name: "cliente",
                                                    title: "Cliente",
                                                    orderable: true,
                                                    searchable: false,
                                                    responsivePriority: 1
                                                },
                                                {
                                                    data: "solicitantes.nome",
                                                    name: "solicitantes",
                                                    title: "Solicitante",
                                                    orderable: true,
                                                    searchable: false,
                                                    responsivePriority: 1
                                                },
                                                {
                                                    data: "itinerarios.itinerario",
                                                    name: "itinerarios.itinerario",
                                                    title: "Itinerario",
                                                    orderable: true,
                                                    searchable: false,
                                                    responsivePriority: 1
                                                },
                                            ],
                                            order: [
                                                [0, "desc"]
                                            ],
                                            responsive: true,
                                            autoWidth: false,
                                            dom: "<\"toolbar\">Blfrtip",
                                            buttons: [],
                                        });
                                        $(e.element).find("#cod_tipo_veiculo,#cod_veiculo").on("change", function () {
                                            $(e.element).find("#tarifas_table").dataTable().api().ajax.reload()

                                        })
                                        $("#tarifas_table").DataTable({
                                            serverSide: true,
                                            processing: true,

                                            ajax: {
                                                url: TaxiReis.functions.core.getURL() + "/tarifas",
                                                data: function (d) {
                                                    const indexed_array = {};
                                                    let cod_tipo_veh = $(e.element).find("#cod_tipo_veiculo").val();
                                                    indexed_array["cod_cliente"] = solicitacao.cod_cliente;
                                                    let tipo_veiculo = 6;
                                                    try {
                                                        if (solicitacao.itinerarios !== null) {
                                                            tipo_veiculo = solicitacao.itinerarios.cod_tipo_veiculo;
                                                        }
                                                    } catch (e) {
                                                        console.info("Solicitação não contem itinerario")
                                                        tipo_veiculo = $(e.element).find("#cod_veiculo").val();
                                                    }
                                                    indexed_array["cod_veiculo"] = tipo_veiculo;
                                                    indexed_array["cod_tipo_veiculo"] = cod_tipo_veh;
                                                    d.filters = indexed_array;
                                                }
                                            },
                                            columns: [{
                                                data: "id",
                                                name: "id",
                                                title: "Id",
                                                orderable: true,
                                                searchable: true,
                                                defaultContent: "Nenhum Valor"
                                            },
                                            {
                                                data: "tarifa",
                                                name: "tarifa",
                                                title: "Tarifa",
                                                orderable: true,
                                                searchable: true,
                                                defaultContent: "Nenhum Valor"
                                            },
                                            {
                                                data: "contrato.numero",
                                                name: "contrato.numero",
                                                title: "Contrato",
                                                orderable: true,
                                                searchable: true,
                                                defaultContent: "Nenhum Valor"
                                            },
                                            {
                                                data: "cod_tipo_veiculo",
                                                name: "cod_tipo_veiculo",
                                                title: "Tipo de Veiculo",
                                                orderable: true,
                                                searchable: true,
                                                defaultContent: "Nenhum Valor",
                                                render: function (data, type, full, meta) {
                                                    return function () {
                                                        const tipo_veh = JSON.parse('{"6":"Carro","8":"Van","9":"Carro 0.97","10":"Carro 1.14","11":"Van 1.57","12":"carro Gabi","13":"VAN Gabi","14":"Carro - GABI 130","15":"Carro Gabi Triunfo","17":"Micro-\u00f4nibus"}');
                                                        return tipo_veh[data];
                                                    };
                                                },
                                            },
                                            {
                                                data: "valor_hora_parada",
                                                name: "valor_hora_parada",
                                                title: "Valor Hora Parada",
                                                orderable: true,
                                                searchable: true,
                                                defaultContent: "Nenhum Valor",
                                                render: function (data, type, full, meta) {
                                                    return function () {
                                                        const formatter = new Intl.NumberFormat("pt-br", {
                                                            style: "currency",
                                                            currency: "BRL"
                                                        });
                                                        var value = parseFloat($("#horas_paradas").val().replace(".", "").replace(",", "."))
                                                        if (value > 0) {
                                                            return (formatter.format(data * value))
                                                        }
                                                        return formatter.format(data);
                                                    };
                                                },
                                            },
                                            {
                                                data: "valor_km_livre",
                                                name: "valor_km_livre",
                                                title: "Valor Km Livre",
                                                orderable: true,
                                                searchable: true,
                                                defaultContent: "Nenhum Valor",
                                                render: function (data, type, full, meta) {
                                                    return function () {
                                                        var formatter = new Intl.NumberFormat("pt-br", {
                                                            style: "currency",
                                                            currency: "BRL"
                                                        });

                                                        return formatter.format(data);
                                                    };
                                                },
                                            },
                                            {
                                                data: "valor_km_excedido",
                                                name: "valor_km_excedido",
                                                title: "Valor Km Excedido",
                                                orderable: true,
                                                searchable: true,
                                                defaultContent: "Nenhum Valor",
                                                render: function (data, type, full, meta) {
                                                    return function () {
                                                        var formatter = new Intl.NumberFormat("pt-br", {
                                                            style: "currency",
                                                            currency: "BRL"
                                                        });
                                                        var value = parseInt($("#km_extras").val())
                                                        if (value > 0) {
                                                            return (formatter.format(data * value))
                                                        }
                                                        return formatter.format(data);
                                                    };
                                                },
                                            },
                                            {
                                                data: "valor_diaria",
                                                name: "valor_diaria",
                                                title: "Valor Diaria",
                                                orderable: true,
                                                searchable: true,
                                                defaultContent: "Nenhum Valor",
                                                render: function (data, type, full, meta) {
                                                    return function () {
                                                        var formatter = new Intl.NumberFormat("pt-br", {
                                                            style: "currency",
                                                            currency: "BRL"
                                                        });

                                                        return formatter.format(data);
                                                    };
                                                },
                                            },
                                            {
                                                data: "valor_carro_disposicao",
                                                name: "valor_carro_disposicao",
                                                title: "Valor Carro Disposicao",
                                                orderable: true,
                                                searchable: true,
                                                defaultContent: "Nenhum Valor",
                                                render: function (data, type, full, meta) {
                                                    return function () {
                                                        var formatter = new Intl.NumberFormat("pt-br", {
                                                            style: "currency",
                                                            currency: "BRL"
                                                        });
                                                        let value = 0;
                                                        try {
                                                            value = parseFloat(solicitacao.itinerarios.quant_kms);
                                                        } catch (e) {
                                                            console.info(e)
                                                        }
                                                        if (value > 0) {
                                                            return (formatter.format(data * value))
                                                        }
                                                        return formatter.format(data);
                                                    };
                                                },
                                            },
                                            {
                                                data: "valor_mensal",
                                                name: "valor_mensal",
                                                title: "Valor Mensal",
                                                orderable: true,
                                                searchable: true,
                                                defaultContent: "Nenhum Valor",
                                                render: function (data, type, full, meta) {
                                                    return function () {
                                                        var formatter = new Intl.NumberFormat("pt-br", {
                                                            style: "currency",
                                                            currency: "BRL"
                                                        });
                                                        return formatter.format(data);
                                                    };
                                                },
                                            },
                                            ],
                                            responsive: true,
                                            autoWidth: false,
                                            dom: "<\"toolbar\">Blfrtip",
                                            buttons: [],
                                            initComplete: function () {

                                                $.contextMenu({
                                                    selector: "#" + this.attr("id") + " tbody tr",
                                                    callback: function (t, i) {
                                                        switch (t) {
                                                            case "edit":
                                                                TaxiReis.EditItem(i.$trigger.attr("id"), "tarifas");
                                                                break;
                                                            case "add":
                                                                TaxiReis.AddItem("tarifas", extra_params);
                                                                break;
                                                        }
                                                    },
                                                    items: {
                                                        add: {
                                                            name: "Adicionar",
                                                            icon: "fa-add"
                                                        },
                                                        edit: {
                                                            name: "Editar",
                                                            icon: "fa-edit"
                                                        }
                                                    },
                                                });
                                            },
                                        });
                                        $(e.element).on('draw.dt', function () {
                                            $("#km_extras").trigger("change");
                                            $("#horas_paradas").trigger("change");
                                        });
                                        $(e.element).on("click", "button[type='submit']", function (a) {
                                            let form_data = {};
                                            let inputs = $(e.element).find("#formAdd input,textarea");
                                            let selects = $(e.element).find("#formAdd select");
                                            $.each(inputs, function (k, v) {
                                                let value = $(v).val()
                                                let name = $(v).attr('name');
                                                let type = $(v).is('select') === true ? "select" : $(v).attr('type')
                                                switch (type) {
                                                    case "number":
                                                        value = parseFloat(value);
                                                        if (!isNaN(value)) {
                                                            form_data[name] = value;
                                                        }
                                                        break;
                                                    case "checkbox":
                                                        form_data[name] = $(v).is(":checked") === true ? 1 : 0;
                                                        break;
                                                    case "select":
                                                        let valu = form_data[name] = $(v).val();
                                                        if (!isNaN(valu) && valu !== null) {
                                                            form_data[name] = valu;
                                                        } else {
                                                            form_data[name] = null;
                                                        }
                                                        break;
                                                    default:
                                                        form_data[name] = $(v).val();
                                                }
                                            })
                                            $.each(selects, function (k, v) {
                                                let value = parseInt($(v).val())
                                                let name = $(v).attr('name');
                                                if (!isNaN(value) && value !== null) {
                                                    form_data[name] = value;
                                                } else {
                                                    form_data[name] = null;
                                                }
                                            });
                                            form_data["verificar_motorista"] = true;
                                            const sendData = () => {
                                                $.ajax({
                                                    url: "api/v2/faturas",
                                                    type: "post",
                                                    dataType: "json",
                                                    data: form_data,
                                                    success: function (a) {
                                                        console.info(JSON.stringify($(e.element).find("form").serialize()));
                                                        $(e.element).modal("hide");
                                                        if (a.error === true) {
                                                            toastr.error(a.message);
                                                        } else {
                                                            toastr.success("Fatura adicionada com sucesso");
                                                            window.open("/faturas/impressao/" + a.data.id, '_blank').focus();

                                                        }

                                                        $.each(window.LaravelDataTables, function (k, v) {
                                                            v.ajax.reload();
                                                        })
                                                    },
                                                    error: function (data) {
                                                        const response = data.responseJSON;
                                                        if (typeof response.errors !== 'undefined' && Object.keys(response.errors).length > 0) {
                                                            $.each(response.errors, function (k, v) {
                                                                if (v.length > 0) {
                                                                    $.each(v, function (key, value) {
                                                                        toastr.error(value);
                                                                    })
                                                                } else {
                                                                    toastr.error(v)
                                                                }
                                                            })
                                                            TaxiReis.MaskAll();
                                                        } else {
                                                            if (typeof response.message !== 'undefined') {
                                                                toastr.error(response.message)
                                                            } else {
                                                                toastr.error(response)
                                                            }
                                                        }
                                                    }
                                                });
                                            }
                                            a.preventDefault()
                                            const getTipoVeh = (id) => {
                                                return id === 8 ? "Van" : "Carro"
                                            }
                                            if (solicitacao?.itinerarios?.cod_tipo_veiculo && solicitacao?.itinerarios.cod_tipo_veiculo != $(e.element).find("#cod_tipo_veiculo").val()) {

                                                modals.confirm({
                                                    title: "Tipo de veiculo diferente do itinerario",
                                                    body: `O itinerario está cadastrado para ${getTipoVeh(solicitacao.itinerarios.cod_tipo_veiculo)} e está selecionado ${getTipoVeh($(e.element).find("#cod_tipo_veiculo").val())} tem certeza que deseja prosseguir?`,
                                                    textTrue: "Sim",
                                                    textFalse: "Não",

                                                    onSubmit: function (e) {
                                                        if (e) {
                                                            sendData()
                                                        }
                                                    }
                                                })
                                            } else {
                                                sendData()
                                            }
                                        }),
                                            TaxiReis.SetupSelects($(e.element));
                                        calcularTotal();
                                        $(e.element).find("#km_extras,#horas_paradas,#despesas_adicionais,#estacionamento,#pedagio,#barcas").on('change', function () {
                                            calcularTotal();
                                        });
                                        const formatter = new Intl.NumberFormat('pt-BR', {
                                            style: 'currency',
                                            currency: 'BRL',
                                        });

                                        $(e.element).find("#horas_paradas").change(function () {
                                            let tarifas = $(e.element).find("#tarifas_table").dataTable().api().data().toArray();
                                            let taxa_van = null;
                                            let taxa_carro = null;
                                            let v_ = this.value
                                            if (typeof tarifas === "undefined" || tarifas === null || tarifas.length === 0) {
                                                taxa_carro = taxa_carro = v_ * 1
                                            }
                                            $.each(tarifas, function (k, v) {
                                                if (v.cod_tipo_veiculo === 6) {
                                                    taxa_carro = v_ * v.valor_hora_parada;
                                                } else if (v.cod_tipo_veiculo === 8) {
                                                    taxa_van = v_ * v.valor_hora_parada;
                                                }
                                            })

                                            let v_carro = formatter.format(taxa_carro);
                                            let v_van = formatter.format(taxa_van)
                                            if (taxa_carro === null) {
                                                this.removeAttribute("carro")
                                            } else {
                                                this.setAttribute("carro", taxa_carro)
                                            }
                                            if (taxa_van === null) {
                                                this.removeAttribute("van")
                                            } else {
                                                this.setAttribute("van", taxa_van)
                                            }

                                            let ht = "";
                                            if (taxa_van != null && taxa_carro != null) {
                                                ht = "<small orignal_val='" + taxa_carro + "' id='taxa_carro'><i class=\"mdi mdi-car-side\" style=\"padding-right: 5px;\"></i>" + v_carro + "</small>"
                                                ht = ht + "<small orignal_val='" + taxa_van + "' id='taxa_van'><i class=\"mdi mdi-van-passenger\" style=\"padding-right: 5px;\"></i>  " + v_van + "  </small>"
                                            } else {
                                                if (taxa_carro != null) {
                                                    ht = "<small orignal_val='" + taxa_carro + "' id='taxa_van'><i class=\"mdi mdi-car-side\" style=\"padding-right: 5px;\"></i>  " + v_carro + "  </small>"
                                                }
                                                if (taxa_van != null) {
                                                    ht = "<small orignal_val='" + taxa_van + "' id='taxa_van'><i class=\"mdi mdi-van-passenger\" style=\"padding-right: 5px;\"></i> " + v_van + "  </small>"
                                                }
                                            }
                                            $(e.element).find("#horas_paradas_right").html(ht)
                                        })
                                        $(e.element).find("#km_extras").change(function () {
                                            let tarifas = $(e.element).find("#tarifas_table").dataTable().api().data().toArray();
                                            let taxa_van = null;
                                            let taxa_carro = null;

                                            let v_ = this.value
                                            if (typeof tarifas === "undefined" || tarifas === null || tarifas.length === 0) {
                                                taxa_carro = taxa_carro = v_ * 1
                                            }
                                            console.info(tarifas)
                                            $.each(tarifas, function (k, v) {
                                                if (v.cod_tipo_veiculo === 6) {
                                                    taxa_carro = v_ * v.valor_km_excedido;
                                                } else if (v.cod_tipo_veiculo === 8) {
                                                    taxa_van = v_ * v.valor_km_excedido;
                                                }
                                            })
                                            if (taxa_carro === null) {
                                                this.removeAttribute("carro")
                                            } else {
                                                this.setAttribute("carro", taxa_carro)
                                            }
                                            if (taxa_van === null) {
                                                this.removeAttribute("van")
                                            } else {
                                                this.setAttribute("van", taxa_van)
                                            }
                                            let v_carro = formatter.format(taxa_carro);
                                            let v_van = formatter.format(taxa_van)
                                            let ht = "";
                                            if (taxa_van != null && taxa_carro != null) {
                                                ht = "<small><i class=\"mdi mdi-car-side\" style=\"padding-right: 5px;\"></i>  " + v_carro + "  </small>"
                                                ht = ht + "<small><i class=\"mdi mdi-van-passenger\" style=\"padding-right: 5px;\"></i>  " + v_van + "  </small>"
                                            } else {
                                                if (taxa_carro != null) {
                                                    ht = "<small><i class=\"mdi mdi-car-side\" style=\"padding-right: 5px;\"></i>  " + v_carro + "  </small>"
                                                }
                                                if (taxa_van != null) {
                                                    ht = "<small><i class=\"mdi mdi-van-passenger\" style=\"padding-right: 5px;\"></i>  " + v_van + "  </small>"
                                                }
                                            }
                                            $(e.element).find("#km_extras_right").html(ht)
                                        })
                                    },
                                });
                            },
                            complete: function () {
                                $.LoadingOverlay("hide");
                            },
                        });
                }
            },
            clientes: {
                editar: function (id, type = null) {
                    let cur_id = id;
                    $.ajax({
                        type: "GET",
                        url: "/api/v2/forms/clientes/edit/" + cur_id,
                        dataType: "json",
                        success: function (data) {
                            modals.modal({
                                title: "",
                                body: data.html + "<table id='contratos_table'> ",
                                size: "xl",
                                footer: '<button type="button" class="btn btn-danger" data-bs-dismiss="modal">Cancelar</button><button type="submit" class="btn btn-success" id="this.id">Atualizar</button>',
                                onCreate: function (modal) {
                                    // create event handler for form submit and handle values
                                    TaxiReis.SetupSelects(modal.element);
                                    TaxiReis.MaskAll();
                                    const extra_params = {};
                                    extra_params.cod_cliente = id;
                                    $(modal.element).on("click", "button[type='submit']", function (event) {
                                        event.preventDefault();
                                        TaxiReis.UnMaskAll();

                                        let form_data = {};
                                        let inputs = $(modal.element).find("#formAdd input,textarea");
                                        let selects = $(modal.element).find("#formAdd select");
                                        $.each(inputs, function (k, v) {
                                            let value = $(v).val()
                                            let name = $(v).attr('name');
                                            let type = $(v).is('select') === true ? "select" : $(v).attr('type')
                                            switch (type) {
                                                case "number":
                                                    value = parseFloat(value);
                                                    if (!isNaN(value)) {
                                                        form_data[name] = value;
                                                    }
                                                    break;
                                                case "checkbox":
                                                    form_data[name] = $(v).is(":checked") === true ? 1 : 0;
                                                    break;
                                                case "select":
                                                    let valu = form_data[name] = $(v).val();
                                                    if (!isNaN(valu) && valu !== null) {
                                                        form_data[name] = valu;
                                                    } else {
                                                        form_data[name] = null;
                                                    }
                                                    break;
                                                default:
                                                    form_data[name] = $(v).val();
                                            }
                                        })
                                        $.each(selects, function (k, v) {
                                            let value = parseInt($(v).val())
                                            let name = $(v).attr('name');
                                            if (!isNaN(value) && value !== null) {
                                                form_data[name] = value;
                                            } else {
                                                form_data[name] = null;
                                            }
                                        })
                                        console.info(JSON.stringify(form_data));
                                        $.ajax({
                                            url: 'api/v2/clientes/' + cur_id,
                                            type: 'patch',
                                            data: JSON.stringify(form_data),
                                            contentType: 'application/json',
                                            processData: false,
                                            dataType: 'json',
                                            success: function (data) {
                                                console.info(data);

                                                $(".modal-backdrop").remove();
                                                $('body').removeClass('modal-open');
                                                if (data.error === true) {
                                                    toastr.success(data.message);
                                                } else {
                                                    toastr.success(data.message);
                                                }
                                                $(modal.element).modal('hide');
                                                $.each(window.LaravelDataTables, function (k, v) {
                                                    v.ajax.reload();
                                                })
                                            },
                                            error: function (data) {
                                                const response = data.responseJSON;
                                                if (typeof response.errors !== 'undefined' && Object.keys(response.errors).length > 0) {
                                                    $.each(response.errors, function (k, v) {
                                                        if (v.length > 0) {
                                                            $.each(v, function (key, value) {
                                                                toastr.error(value);
                                                            })
                                                        } else {
                                                            toastr.error(v)
                                                        }
                                                    })
                                                    TaxiReis.MaskAll();
                                                } else {
                                                    if (typeof response.message !== 'undefined') {
                                                        toastr.error(response.message)
                                                    } else {
                                                        toastr.error(response)
                                                    }
                                                }
                                            }
                                        });
                                    });
                                    $("#contratos_table").DataTable({
                                        serverSide: true,
                                        processing: true,
                                        ajax: {
                                            url: TaxiReis.functions.core.getURL() + "/contratos",
                                            data: function (d) {
                                                d.filters = extra_params;
                                            }
                                        },
                                        columns: [{
                                            name: "numero",
                                            data: "numero",
                                            title: "Contrato",
                                            orderable: true,
                                            searchable: true
                                        },
                                        {
                                            name: "clientes.nome_fantasia",
                                            data: "clientes.nome_fantasia",
                                            title: "Cliente",
                                            orderable: true,
                                            searchable: true
                                        },
                                        ],
                                        order: [
                                            [0, "desc"]
                                        ],
                                        responsive: true,
                                        autoWidth: false,
                                        dom: "<\"toolbar\">Blfrtip",
                                        buttons: [],
                                        initComplete: function () {
                                            $.contextMenu({
                                                selector: "#" + this.attr("id") + " tbody tr",
                                                callback: function (t, i) {
                                                    switch (t) {
                                                        case "edit":
                                                            TaxiReis.EditarItem(i.$trigger.attr("id"), "contratos");
                                                            break;
                                                        case "add":
                                                            TaxiReis.AddItem("contratos", extra_params);
                                                            break;
                                                    }
                                                },
                                                items: {
                                                    add: {
                                                        name: "Adicionar",
                                                        icon: "fa-add"
                                                    },
                                                    edit: {
                                                        name: "Editar",
                                                        icon: "fa-edit"
                                                    }
                                                },
                                            });
                                        },
                                    });
                                    $("#empresas_table").DataTable({
                                        serverSide: true,
                                        processing: true,
                                        ajax: {
                                            url: TaxiReis.functions.core.getURL() + "/empresas",
                                            data: function (d) {


                                                d.filters = extra_params;
                                            }
                                        },
                                        columns: [{
                                            name: "id",
                                            data: "id",
                                            title: "Id",
                                            orderable: true,
                                            searchable: true
                                        },
                                        {
                                            name: "nome_fantasia",
                                            data: "nome_fantasia",
                                            title: "Nome Fantasia",
                                            orderable: true,
                                            searchable: true
                                        },
                                        {
                                            name: "endereco",
                                            data: "endereco",
                                            title: "Endere\u00e7o",
                                            orderable: true,
                                            searchable: true
                                        },
                                        {
                                            name: "cidade",
                                            data: "cidade",
                                            title: "Cidade",
                                            orderable: true,
                                            searchable: true
                                        },
                                        {
                                            name: "estado",
                                            data: "estado",
                                            title: "Estado",
                                            orderable: true,
                                            searchable: true
                                        },
                                        ],
                                        order: [
                                            [0, "desc"]
                                        ],
                                        responsive: true,
                                        autoWidth: false,
                                        dom: "<\"toolbar\">Blfrtip",
                                        buttons: [],
                                        initComplete: function () {
                                            $.contextMenu({
                                                selector: "#" + this.attr("id") + " tbody tr",
                                                callback: function (t, i) {
                                                    switch (t) {
                                                        case "edit":
                                                            TaxiReis.EditarItem(i.$trigger.attr("id"), "empresas");
                                                            break;
                                                        case "add":
                                                            TaxiReis.AddItem("empresas", extra_params);
                                                            break;


                                                    }
                                                },
                                                items: {
                                                    add: {
                                                        name: "Adicionar",
                                                        icon: "fa-add"
                                                    },
                                                    edit: {
                                                        name: "Editar",
                                                        icon: "fa-edit"
                                                    }
                                                },
                                            });
                                        },
                                    });
                                    $("#c_custos_table").DataTable({
                                        serverSide: true,
                                        processing: true,
                                        ajax: {
                                            url: TaxiReis.functions.core.getURL() + "/c_custo",
                                            data: function (d) {

                                                d.filters = extra_params;
                                            }
                                        },
                                        columns: [{
                                            name: "id",
                                            data: "id",
                                            title: "Id",
                                            orderable: true,
                                            searchable: true
                                        },
                                        {
                                            name: "descricao",
                                            data: "descricao",
                                            title: "Descricao",
                                            orderable: true,
                                            searchable: true
                                        },
                                        {
                                            name: "agrupador_1",
                                            data: "agrupador_1",
                                            title: "Agrupador 1",
                                            orderable: true,
                                            searchable: true
                                        },
                                        {
                                            name: "agrupador_2",
                                            data: "agrupador_2",
                                            title: "Agrupador 2",
                                            orderable: true,
                                            searchable: true
                                        },
                                        {
                                            name: "agrupador_3",
                                            data: "agrupador_3",
                                            title: "Agrupador 3",
                                            orderable: true,
                                            searchable: true
                                        },
                                        {
                                            name: "empresa.nome_fantasia",
                                            data: "empresa.nome_fantasia",
                                            title: "Empresa",
                                            orderable: true,
                                            searchable: true
                                        },
                                        {
                                            name: "ativo",
                                            data: "ativo",
                                            render: function (data, type, full, meta) {
                                                if (data === 1) {
                                                    return '<div onclick="TaxiReis.ToggleActiveItem(' + full.id + ', false, \'c_custo\', function() {$(\'#c_custos_table\').DataTable().ajax.reload();})"> <i class="mdi mdi-check" style="font-size: 25px; color: green;"></i></div>';
                                                }
                                                return '<div onclick="TaxiReis.ToggleActiveItem(' + full.id + ', true, \'c_custo\', function() {$(\'#c_custos_table\').DataTable().ajax.reload();})"> <i class="mdi mdi-close" style="font-size: 25px; color: #ff0000;"></i></div>';
                                            },
                                            title: "Ativo",
                                            orderable: true,
                                            searchable: true,
                                        },
                                        ],
                                        order: [
                                            [0, "desc"]
                                        ],
                                        responsive: true,
                                        autoWidth: false,
                                        dom: "<\"toolbar\">Blfrtip",
                                        buttons: [],
                                        initComplete: function () {

                                            $.contextMenu({
                                                selector: "#" + this.attr("id") + " tbody tr",
                                                callback: function (t, i) {
                                                    switch (t) {
                                                        case "edit":
                                                            TaxiReis.EditarItem(i.$trigger.attr("id"), "tarifas");
                                                            break;
                                                        case "add":
                                                            TaxiReis.AddItem("tarifas", extra_params);
                                                            break;
                                                    }
                                                },
                                                items: {
                                                    add: {
                                                        name: "Adicionar",
                                                        icon: "fa-add"
                                                    },
                                                    edit: {
                                                        name: "Editar",
                                                        icon: "fa-edit"
                                                    }
                                                },
                                            });
                                        },
                                    });
                                    $("#tarifas_table").DataTable({
                                        serverSide: true,
                                        processing: true,
                                        ajax: {
                                            url: TaxiReis.functions.core.getURL() + "/tarifas",
                                            data: function (d) {

                                                d.filters = extra_params;
                                            }
                                        },
                                        columns: [{
                                            data: "id",
                                            name: "id",
                                            title: "Id",
                                            orderable: true,
                                            searchable: true,
                                            defaultContent: "Nenhum Valor"
                                        },
                                        {
                                            data: "tarifa",
                                            name: "tarifa",
                                            title: "Tarifa",
                                            orderable: true,
                                            searchable: true,
                                            defaultContent: "Nenhum Valor"
                                        },
                                        {
                                            data: "contrato.numero",
                                            name: "contrato.numero",
                                            title: "Contrato",
                                            orderable: true,
                                            searchable: true,
                                            defaultContent: "Nenhum Valor"
                                        },
                                        {
                                            data: "cod_tipo_veiculo",
                                            name: "cod_tipo_veiculo",
                                            title: "Tipo de Veiculo",
                                            orderable: true,
                                            searchable: true,
                                            defaultContent: "Nenhum Valor",
                                            render: function (data, type, full, meta) {
                                                return function () {
                                                    const tipo_veh = JSON.parse('{"6":"Carro","8":"Van","9":"Carro 0.97","10":"Carro 1.14","11":"Van 1.57","12":"carro Gabi","13":"VAN Gabi","14":"Carro - GABI 130","15":"Carro Gabi Triunfo","17":"Micro-\u00f4nibus"}');
                                                    return tipo_veh[data];
                                                };
                                            },
                                        },
                                        {
                                            data: "valor_hora_parada",
                                            name: "valor_hora_parada",
                                            title: "Valor Hora Parada",
                                            orderable: true,
                                            searchable: true,
                                            defaultContent: "Nenhum Valor",
                                            render: function (data, type, full, meta) {
                                                return function () {
                                                    const formatter = new Intl.NumberFormat("pt-br", {
                                                        style: "currency",
                                                        currency: "BRL"
                                                    });
                                                    return formatter.format(data);
                                                };
                                            },
                                        },
                                        {
                                            data: "valor_km_livre",
                                            name: "valor_km_livre",
                                            title: "Valor Km Livre",
                                            orderable: true,
                                            searchable: true,
                                            defaultContent: "Nenhum Valor",
                                            render: function (data, type, full, meta) {
                                                return function () {
                                                    var formatter = new Intl.NumberFormat("pt-br", {
                                                        style: "currency",
                                                        currency: "BRL"
                                                    });
                                                    return formatter.format(data);
                                                };
                                            },
                                        },
                                        {
                                            data: "valor_km_excedido",
                                            name: "valor_km_excedido",
                                            title: "Valor Km Excedido",
                                            orderable: true,
                                            searchable: true,
                                            defaultContent: "Nenhum Valor",
                                            render: function (data, type, full, meta) {
                                                return function () {
                                                    var formatter = new Intl.NumberFormat("pt-br", {
                                                        style: "currency",
                                                        currency: "BRL"
                                                    });
                                                    return formatter.format(data);
                                                };
                                            },
                                        },
                                        {
                                            data: "valor_diaria",
                                            name: "valor_diaria",
                                            title: "Valor Diaria",
                                            orderable: true,
                                            searchable: true,
                                            defaultContent: "Nenhum Valor",
                                            render: function (data, type, full, meta) {
                                                return function () {
                                                    var formatter = new Intl.NumberFormat("pt-br", {
                                                        style: "currency",
                                                        currency: "BRL"
                                                    });
                                                    return formatter.format(data);
                                                };
                                            },
                                        },
                                        {
                                            data: "valor_carro_disposicao",
                                            name: "valor_carro_disposicao",
                                            title: "Valor Carro Disposicao",
                                            orderable: true,
                                            searchable: true,
                                            defaultContent: "Nenhum Valor",
                                            render: function (data, type, full, meta) {
                                                return function () {
                                                    var formatter = new Intl.NumberFormat("pt-br", {
                                                        style: "currency",
                                                        currency: "BRL"
                                                    });
                                                    return formatter.format(data);
                                                };
                                            },
                                        },
                                        {
                                            data: "valor_mensal",
                                            name: "valor_mensal",
                                            title: "Valor Mensal",
                                            orderable: true,
                                            searchable: true,
                                            defaultContent: "Nenhum Valor",
                                            render: function (data, type, full, meta) {
                                                return function () {
                                                    var formatter = new Intl.NumberFormat("pt-br", {
                                                        style: "currency",
                                                        currency: "BRL"
                                                    });
                                                    return formatter.format(data);
                                                };
                                            },
                                        },
                                        ],
                                        order: [
                                            [0, "desc"]
                                        ],
                                        responsive: true,
                                        autoWidth: false,
                                        dom: "<\"toolbar\">Blfrtip",
                                        buttons: [],
                                        initComplete: function () {

                                            $.contextMenu({
                                                selector: "#" + this.attr("id") + " tbody tr",
                                                callback: function (t, i) {
                                                    switch (t) {
                                                        case "edit":
                                                            TaxiReis.EditarItem(i.$trigger.attr("id"), "tarifas");
                                                            break;
                                                        case "add":
                                                            TaxiReis.AddItem("tarifas", extra_params);
                                                            break;
                                                    }
                                                },
                                                items: {
                                                    add: {
                                                        name: "Adicionar",
                                                        icon: "fa-add"
                                                    },
                                                    edit: {
                                                        name: "Editar",
                                                        icon: "fa-edit"
                                                    }
                                                },
                                            });
                                        },
                                    });
                                    $("#solicitantes_table").DataTable({
                                        serverSide: true,
                                        processing: true,
                                        ajax: {
                                            url: TaxiReis.functions.core.getURL() + "/solicitantes",
                                            data: function (d) {

                                                d.filters = extra_params;
                                            }
                                        },

                                        columns: [

                                            {
                                                name: "id",
                                                data: "id",
                                                title: "Id",
                                                orderable: true,
                                                searchable: true
                                            },
                                            {
                                                name: "nome",
                                                data: "nome",
                                                title: "Nome",
                                                orderable: true,
                                                searchable: true
                                            },
                                            {
                                                name: "cliente.nome_fantasia",
                                                data: "cliente.nome_fantasia",
                                                title: "Cliente",
                                                orderable: true,
                                                searchable: true
                                            },
                                            {
                                                name: "login",
                                                data: "login",
                                                title: "Login",
                                                orderable: true,
                                                searchable: true
                                            },
                                            {
                                                name: "matricula",
                                                data: "matricula",
                                                title: "Matricula",
                                                orderable: true,
                                                searchable: true
                                            },
                                            {
                                                name: "ativo",
                                                data: "ativo",
                                                title: "Ativo",
                                                render: function (data, type, full, meta) {
                                                    if (data === 1) {
                                                        return '<div onclick="TaxiReis.ToggleActiveItem(' + full.id + ', false, \'solicitantes\', function() {$(\'#solicitantes_table\').DataTable().ajax.reload();})"> <i class="mdi mdi-check" style="font-size: 25px; color: green;"></i></div>';
                                                    }
                                                    return '<div onclick="TaxiReis.ToggleActiveItem(' + full.id + ', true, \'solicitantes\', function() {$(\'#solicitantes_table\').DataTable().ajax.reload();})"> <i class="mdi mdi-close" style="font-size: 25px; color: red;"></i></div>';
                                                },
                                                orderable: true,
                                                searchable: true
                                            },
                                            {
                                                data: "action",
                                                name: "action",
                                                title: "Action",
                                                orderable: false,
                                                searchable: false,
                                                width: 60,
                                                className: "text-center"
                                            },
                                        ],
                                        order: [
                                            [0, "desc"]
                                        ],
                                        responsive: true,
                                        autoWidth: false,
                                        dom: "<\"toolbar\">Blfrtip",
                                        buttons: [],
                                        initComplete: function () {

                                            $.contextMenu({
                                                selector: "#" + this.attr("id") + " tbody tr",
                                                callback: function (t, i) {
                                                    switch (t) {
                                                        case "edit":
                                                            TaxiReis.EditarItem(i.$trigger.attr("id"), "solicitantes");
                                                            break;
                                                        case "add":
                                                            TaxiReis.AddItem("solicitantes", extra_params);
                                                            break;
                                                    }
                                                },
                                                items: {
                                                    add: {
                                                        name: "Adicionar",
                                                        icon: "fa-add"
                                                    },
                                                    edit: {
                                                        name: "Editar",
                                                        icon: "fa-edit"
                                                    }
                                                },
                                            });
                                        },
                                    });
                                    $("#itinerarios_table").DataTable({
                                        serverSide: true,
                                        processing: true,
                                        ajax: {
                                            url: TaxiReis.functions.core.getURL() + "/itinerarios",
                                            data: function (d) {

                                                d.filters = extra_params;
                                            }
                                        },
                                        columnDefs: [{
                                            orderable: false,
                                            className: 'select-checkbox',
                                            targets: 0
                                        }],
                                        select: {
                                            style: 'multi',
                                            selector: 'td:first-child'
                                        },
                                        columns: [{
                                            "data": function () {
                                                return ""
                                            }
                                        },
                                        {
                                            name: "id",
                                            data: "id",
                                            title: "Id",
                                            orderable: true,
                                            searchable: true
                                        },
                                        {
                                            name: "itinerario",
                                            data: "itinerario",
                                            title: "Itinerario",
                                            orderable: true,
                                            searchable: true
                                        },
                                        {
                                            name: "bases.nome",
                                            data: "bases.nome",
                                            title: "Base",
                                            orderable: true,
                                            searchable: true
                                        },
                                        {
                                            name: "descricao_origem",
                                            data: "descricao_origem",
                                            title: "Descricao Origem",
                                            orderable: true,
                                            searchable: true
                                        },
                                        {
                                            name: "descricao_destino",
                                            data: "descricao_destino",
                                            title: "Descricao Destino",
                                            orderable: true,
                                            searchable: true
                                        },
                                        {
                                            name: "valor",
                                            data: "valor",
                                            title: "Valor",
                                            orderable: true,
                                            searchable: true
                                        },
                                        {
                                            name: "ativo",
                                            data: "ativo",
                                            title: "Ativo",
                                            render: function (data, type, full, meta) {
                                                if (data === 1) {
                                                    return '<div onclick="TaxiReis.ToggleActiveItem(' + full.id + ', false, \'c_custo\', function() {$(\'#itinerarios_table\').DataTable().ajax.reload();})"> <i class="mdi mdi-check" style="font-size: 25px; color: green;"></i></div>';
                                                }
                                                return '<div onclick="TaxiReis.ToggleActiveItem(' + full.id + ', true, \'c_custo\', function() {$(\'#itinerarios_table\').DataTable().ajax.reload();})"> <i class="mdi mdi-close" style="font-size: 25px; color: red;"></i></div>';
                                            },
                                            orderable: true,
                                            searchable: true
                                        },
                                        {
                                            name: "restrito",
                                            data: "restrito",
                                            title: "Restrito",
                                            render: function (data, type, row, meta) {
                                                return data === 1 ? '<div> <i class="mdi mdi-check" style="font-size: 25px; color: green;"></i></div>' : '<div> <i class="mdi mdi-close" style="font-size: 25px; color: red;"></i></div>';

                                            },
                                            orderable: true,
                                            searchable: true
                                        },
                                        ],
                                        order: [
                                            [1, "desc"]
                                        ],
                                        responsive: true,
                                        autoWidth: false,
                                        dom: "<\"toolbar\">Blfrtip",
                                        buttons: [],
                                        initComplete: function () {
                                            $.contextMenu({
                                                selector: "#" + this.attr("id") + " tbody tr",
                                                callback: function (t, i) {
                                                    let table_data = null;
                                                    let table_arr = null;
                                                    let cur_i_desativar = 0;
                                                    let cur_i_ativar = 0;
                                                    switch (t) {
                                                        case "edit":
                                                            TaxiReis.EditarItem(i.$trigger.attr("id"), "itinerarios");
                                                            break;
                                                        case "add":
                                                            TaxiReis.AddItem("itinerarios", extra_params);
                                                            break;
                                                        case "ativar_selecionados":
                                                            table_data = [];
                                                            table_arr = $("#itinerarios_table").DataTable().rows({
                                                                selected: true
                                                            }).data().toArray();
                                                            if (typeof table_arr[cur_i_desativar] !== 'undefined') {
                                                                function ativar_selecionado() {
                                                                    if (cur_i_ativar >= table_arr.length) {
                                                                        $('#itinerarios_table').DataTable().ajax.reload();
                                                                        return;
                                                                    }
                                                                    $.ajax({
                                                                        url: TaxiReis.functions.core.getURL() + "/api/v2/itinerarios/" + table_arr[cur_i_ativar].id,
                                                                        type: 'patch',
                                                                        data: JSON.stringify({
                                                                            restrito: 1
                                                                        }),
                                                                        contentType: 'application/json',
                                                                        processData: false,
                                                                        dataType: 'json',
                                                                        success: function (result) {
                                                                            toastr.success("Itinerario com o ID " + table_arr[cur_i_ativar].id + " foi atualizado");
                                                                        },
                                                                        error: function (result) {
                                                                            toastr.error(result);
                                                                        },
                                                                        complete: function () {
                                                                            cur_i_ativar++;
                                                                            ativar_selecionado();
                                                                        }
                                                                    });
                                                                }
                                                            } else {
                                                                $('#itinerarios_table').DataTable().ajax.reload();
                                                            }
                                                            ativar_selecionado();
                                                            break;
                                                        case "desativar_selecilados":
                                                            table_data = [];
                                                            table_arr = $(modal.element).find("#itinerarios_table").DataTable().rows({
                                                                selected: true
                                                            }).data().toArray();

                                                            function desativar_selecionado() {
                                                                if (typeof table_arr[cur_i_desativar] !== 'undefined') {
                                                                    $.ajax({
                                                                        url: TaxiReis.functions.core.getURL() + "/api/v2/itinerarios/" + table_arr[cur_i_desativar].id,
                                                                        type: 'patch',
                                                                        data: JSON.stringify({
                                                                            restrito: 0
                                                                        }),
                                                                        contentType: 'application/json',
                                                                        processData: false,
                                                                        dataType: 'json',
                                                                        success: function (result) {
                                                                            toastr.success("Itinerario com o ID " + table_arr[cur_i_ativar].id + " foi atualizado");
                                                                        },
                                                                        error: function (result) {
                                                                            toastr.error(result);
                                                                        },
                                                                        complete: function () {
                                                                            cur_i_desativar++;
                                                                            desativar_selecionado();
                                                                        }
                                                                    });
                                                                } else {
                                                                    $('#itinerarios_table').DataTable().ajax.reload();
                                                                }
                                                            }

                                                            desativar_selecionado();
                                                            break;
                                                    }
                                                },
                                                items: {
                                                    add: {
                                                        name: "Adicionar",
                                                        icon: "fa-plus"
                                                    },
                                                    edit: {
                                                        name: "Editar",
                                                        icon: "fa-edit"
                                                    },
                                                    selecionados: {
                                                        name: "Items Selecionados",
                                                        icon: "fa-check",
                                                        items: {
                                                            ativar_selecionados: {
                                                                icon: "fa-check",
                                                                name: "Restringir itinerario para o cliente"
                                                            },
                                                            desativar_selecilados: {
                                                                icon: "fa-minus",
                                                                name: "Remover retrições do itinerario para o cliente"
                                                            }
                                                        }
                                                    },
                                                },
                                            });
                                        },
                                    });
                                    $("div.toolbar").html('<a><i class="mdi mdi-information text-primary">Clique com o direito sobre a tabela para aparecer as opções.</a>');
                                }
                            })

                            TaxiReis.MaskAll();
                        },
                        complete: function () {
                            $.LoadingOverlay("hide");
                        }
                    });

                }
            },
            solicitacoes: {
                marcarcomo: function (cod_solicitacao, cod_status, status_text) {

                    modals.confirm({
                        title: "Você realmente deseja alterar essa solicitação?",
                        body: "Realmente gostaria de alterar a solicitacao #" + cod_solicitacao + " como " + status_text + "?",
                        textTrue: "Sim",
                        textFalse: "Não",
                        onSubmit: function (e) {
                            if (e) {
                                $.ajax({
                                    type: "PATCH",
                                    url: "/api/v2/solicitacoes/" + cod_solicitacao,
                                    dataType: "json",
                                    data: { cod_status: parseInt(cod_status) },
                                    success: function (data) {
                                        if (!data.error)
                                            toastr["success"]("Status alterado com sucesso");
                                        else
                                            toastr["error"]("Erro: " + data);
                                        $.each(window.LaravelDataTables, function (k, v) {
                                            v.ajax.reload();
                                        })
                                    },
                                    error: function (data) {
                                        toastr["error"]("Erro: " + JSON.stringify(data));
                                    }

                                });
                            }
                        },
                        onDispose: function () {

                        },
                    });
                },
                gerar_relatorio: function () {
                    modals.modal({
                        title: "Gerar Relatório",
                        body: "<form id='form_relatorio'>" +
                            "<label>Data</label>" +
                            "<div class=\"mb-3\">" +
                            "<input class=\"form-control\" name=\"date_filter\" id=\"date_filter\">" +
                            "<label>Motorista</label> " +
                            "</div>" +
                            "<div class=\"input-group  mb-3\">" +
                            "   <select class=\"form-control w-100\" multiple name=\"cod_motorista\" id=\"cod_motorista\"></select>" +
                            "   <div class=\"input-group-text\">\n" +
                            "    <input id='todos_motoristas' name='todos_motoristas' class=\"form-check-input mt-0\" data-bs-toggle=\"tooltip\" data-bs-placement=\"top\"  type=\"checkbox\" value=\"\" title=\"Todos os motorisas\" aria-label=\"Todos os motoristas\">\n" +
                            "   </div>" +
                            "</div>" +
                            "<div class=\"mb-3\">" +
                            "<label>Cliente</label> " +
                            "<select class=\"form-control\" name=\"cod_cliente\" id=\"cod_cliente\"></select>" +
                            "</div>" +
                            "<div class=\"mb-3\">" +
                            "<div class=\"form-check\">" +
                            "  <input class=\"form-check-input\" type=\"checkbox\" value=\"\" id=\"faturas_concluidas\">" +
                            "  <label class=\"form-check-label\" for=\"faturas_concluidas\">" +
                            "    Somente faturas concluidas" +
                            "  </label>" +
                            "</div>" +
                            "</div>" +
                            "</form>",
                        size: "md",
                        footer: '<button type="button" class="btn btn-danger" data-bs-dismiss="modal">Cancelar</button><button type="submit" class="btn btn-success" id="this.id">Gerar</button>',
                        onCreate: function (modal) {
                            var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
                            var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
                                return new bootstrap.Tooltip(tooltipTriggerEl)
                            })
                            let start = moment().subtract(29, 'days');
                            const end = moment();

                            function cb(start, end) {
                                $(modal.element).find('#date_filter span').html(start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY'));
                            }

                            start = moment();
                            TaxiReis.SetupSelects($(modal.element));

                            $(modal.element).find("#date_filter").daterangepicker({
                                startDate: start,
                                endDate: end,
                                cancelClass: "btn-danger",
                                ranges: {
                                    'Hoje': [moment(), moment()],
                                    'Ontem': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                                    'Ultimos 7 dias': [moment().subtract(6, 'days'), moment()],
                                    'Ultimos 30 dias': [moment().subtract(29, 'days'), moment()],
                                    'Este mês': [moment().startOf('month'), moment().endOf('month')],
                                    'Mês Passado': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
                                },
                                "locale": {
                                    "format": "DD/MM/YYYY",
                                    "separator": " - ",
                                    "applyLabel": "Aplicar",
                                    "cancelLabel": "Cancelar",
                                    "customRangeLabel": "Customizado",
                                    "daysOfWeek": [
                                        "Dom",
                                        "Seg",
                                        "Ter",
                                        "Qua",
                                        "Qui",
                                        "Sex",
                                        "Sab"
                                    ],
                                    "monthNames": [
                                        "Janeiro",
                                        "Fevereiro",
                                        "Março",
                                        "Abril",
                                        "Maio",
                                        "Junho",
                                        "Julho",
                                        "Agosto",
                                        "Setembro",
                                        "Outubro",
                                        "Novembro",
                                        "Dezembro"
                                    ],
                                    "firstDay": 1
                                }
                            }, cb);

                            $(modal).find('#date_filter span').html(moment().format('dd/mm/YYYY'));
                            $(modal.element).on("click", "button[type='submit']", function (event) {
                                $.LoadingOverlay("show");

                                const dat = {
                                    somente_concluidas: $("#faturas_concluidas").is(":checked"),
                                    cod_cliente: $(modal.element).find("#cod_cliente").val()
                                }

                                if ($(modal.element).find("#cod_motorista").val().length > 0) {
                                    dat["cod_motorista"] = $(modal.element).find("#cod_motorista").val();
                                }
                                if ($(modal.element).find("#todos_motoristas").is(":checked")) {
                                    dat["cod_motorista"] = true;
                                }

                                $.ajax({
                                    type: "POST",
                                    dataType: "JSON",
                                    data: dat,
                                    url: TaxiReis.functions.core.getURL() + "/gerar_relatorio/" + $(modal.element).find("#date_filter").val().split("/").join("_").split(" - ").join("/"),
                                    success: (response) => {
                                        if (response.error === true) {
                                            toastr.error(response.message)
                                        } else {
                                            toastr.success(response.message);
                                        }
                                    },
                                    error: (response) => {
                                        toastr.error("ocorreu um erro ...")
                                        $.LoadingOverlay("hide");
                                    },
                                    complete: (response) => {
                                        $.LoadingOverlay("hide");
                                    }

                                })
                            });
                        }
                    });
                },
                copiar: {
                    init: function (id, soldata) {
                        modals.modal({
                            title: "",
                            body: "<form id=\"form_teste\">\n" +
                                "<input id='datas_para_copiar' class='form-control' placeholder='Selecione as datas'>" +
                                "</form>\n",
                            size: "md",
                            footer: '<button type="button" class="btn btn-danger" data-bs-dismiss="modal">Cancelar</button><button type="submit" class="btn btn-success" id="this.id">Copiar</button>',
                            onCreate: function (modal) {
                                // create event handler for form submit and handle values
                                $("#datas_para_copiar").datepicker({
                                    multidate: true,
                                    locale: 'pt-BR',
                                    clearBtn: true,
                                });
                                $(modal.element).on("click", "button[type='submit']", function (event) {

                                    $.ajax({
                                        url: "api/v2/actions/solicitacoes/copiar/" + id,
                                        cache: false,
                                        type: 'POST',
                                        dataType: 'json',
                                        contentType: 'application/json',
                                        data: JSON.stringify({
                                            info: $(modal.element).find("#datas_para_copiar").val(),
                                            data: soldata
                                        }),
                                        success: function (data) {
                                            toastr.success("Solicitações copiadas com sucesso")
                                            let json = JSON.parse(data.data);
                                            console.info(json.length)
                                            for (let i = 0; i < json.length; i++) {
                                                const obj = json[i];
                                                toastr.success(`ID: ${json[i].id} / Nova Data: ${json[i].data}`);
                                            }
                                            $("#crud-table").DataTable().ajax.reload();
                                        },
                                        error: function (data) {
                                            const response = data.responseJSON;
                                            if (typeof response.errors !== 'undefined' && Object.keys(response.errors).length > 0) {
                                                $.each(response.errors, function (k, v) {
                                                    if (v.length > 0) {
                                                        $.each(v, function (key, value) {
                                                            toastr.error(value);
                                                        })
                                                    } else {
                                                        toastr.error(v)
                                                    }
                                                })
                                                TaxiReis.MaskAll();
                                            } else {
                                                if (typeof response.message !== 'undefined') {
                                                    toastr.error(response.message)
                                                } else {
                                                    toastr.error(response)
                                                }
                                            }
                                        }
                                    });

                                })
                            }
                        })
                    },
                }
            },
        },
        getFormData: function (e) {
            const t = e.serializeArray(),
                a = {};
            return (
                $.map(t, function (e, t) {
                    a[e.name] = e.value;
                }),
                a
            );
        },
        getMetaModal: function () {
            const metas = document.getElementsByTagName('meta');

            for (let i = 0; i < metas.length; i++) {
                if (metas[i].getAttribute('name') === "metaModal") {
                    return metas[i].getAttribute('content');
                }
            }

            return '';
        },
        SetupDates: function (e) {

        },
        SetupSelects: function (e, t = null, filtro = "") {
            null === this.DefaultValues && (this.DefaultValues = this.getFormData($(e).find("form")));


            $(e).find("select[name='" + filtro + "cod_status']").select2({
                ajax: {
                    url: TaxiReis.functions.core.getURL() + "/api/v2/autocomplete/status",
                    dataType: "json",
                    delay: 1000,
                    cache: true,
                },
                dropdownParent: $(e)
            });
            $(e).find("select[name='" + filtro + "sts']").select2({ dropdownParent: $(e) });
            $(e)
                .find("select[name='" + filtro + "cod_contrato']")
                .select2({
                    dropdownParent: $(e),
                    ajax: {
                        url: TaxiReis.functions.core.getURL() + "/api/v2/autocomplete/contratos",
                        delay: 1000,
                        cache: !0,
                    },
                });
            let estado = $(e)
                .find("select[name='estado']")
                .select2({
                    dropdownParent: $(e),
                    ajax: {
                        url: TaxiReis.functions.core.getURL() + "/api/v2/autocomplete/estados",
                        dataType: "json",
                        delay: 1000,
                        cache: true,
                    },
                });
            let these = this;
            $(e).find("select[name='" + filtro + "cidade']").select2({
                dropdownParent: $(e),
                ajax: {
                    url: function () {
                        return these.functions.core.getURL() + "/api/v2/autocomplete/cidades/" + estado.val();
                    },
                    dataType: "json",
                    delay: 1000,
                    cache: true,
                },
            });
            $(e).find("select[name='" + filtro + "cod_cidade_origem']").select2({ dropdownParent: $(e) });
            $(e).find("select[name='" + filtro + "cod_bairro_origem']").select2({ dropdownParent: $(e) });
            $(e).find("select[name='" + filtro + "cod_cidade_destino']").select2({ dropdownParent: $(e) });
            $(e).find("select[name='" + filtro + "cod_bairro_destino']").select2({ dropdownParent: $(e) });
            $(e).find("select[name='" + filtro + "cod_cidade_origem']");
            $(e).find("select[name='" + filtro + "cod_fs']").select2({ dropdownParent: $(e) });
            $(e).find("select[name='" + filtro + "cod_tipo_veiculo']").select2({ dropdownParent: $(e) });
            $(e).find("select[name='" + filtro + "cod_servico_tipo']").select2({ dropdownParent: $(e) });
            $(e).find("select[name='" + filtro + "cod_prestadora']").select2({ dropdownParent: $(e) });
            $(e)
                .find("select[name='" + filtro + "cod_cliente']")
                .select2({
                    dropdownParent: $(e),
                    width: "resolve",
                    ajax: {
                        url: TaxiReis.functions.core.getURL() + "/api/v2/autocomplete/clientes",
                        dataType: "json",
                        cache: !0,
                    },
                });
            $(e)
                .find("select[name='veiculo']")
                .select2({
                    dropdownParent: $(e),
                    ajax: {
                        url: TaxiReis.functions.core.getURL() + "/api/v2/autocomplete/veiculos",
                        delay: 1000,
                        dataType: "json",
                        processResults: function (e) {
                            return {
                                results: $.map(e, function (e) {
                                    return {
                                        text: e.placa + " - " + e.fabricante + " - " + e.modelo,
                                        id: e.id
                                    };
                                }),
                            };
                        },
                        cache: !0,
                    },
                });
            $(e)
                .find("select[name='" + filtro + "cod_motorista']")
                .select2({
                    dropdownParent: $(e),
                    ajax: {
                        url: TaxiReis.functions.core.getURL() + "/api/v2/autocomplete/motoristas",
                        dataType: "json",
                        delay: 1000,
                        data: function (e) {
                            return {
                                search: e.term,
                                page: e.page || 1
                            };
                        },
                        cache: !0,
                    },
                    templateResult: function (data, container) {
                        if (data.isDisabled === true) {
                            $(container).css({ "background-color": "red" });
                        }
                        return data.text;
                    }
                }).on("select2:select", function () {
                    let dat = $(e).find("select[name='" + filtro + "cod_motorista']").select2("data")[0];
                    console.info(dat);
                    if (typeof dat.veiculo !== "undefined" && dat.veiculo !== null) {
                        let el = $(e)
                            .find("select[name='" + filtro + "cod_veiculo']");
                        el.empty()
                        let veh_id = dat.veiculo.id
                        let veh_placa = dat.veiculo.placa
                        let veh_modelo = dat.veiculo.modelo
                        let veh_fabricante = dat.veiculo.fabricante;


                        $(e).find("#cod_tipo_veiculo").val(dat.veiculo.cod_tipo_veiculo).trigger("change")
                        var o = $("<option/>", {
                            value: veh_id,
                            text: veh_placa + " / " + veh_fabricante + " / " + veh_modelo,
                            selected: true
                        });
                        el.append(o);
                        el.trigger('change');

                    }
                });
            $(e)
                .find("select[name='" + filtro + "cod_solicitante']")
                .select2({
                    dropdownParent: $(e),
                    ajax: {
                        url: TaxiReis.functions.core.getURL() + "/api/v2/autocomplete/solicitantes",
                        dataType: "json",
                        delay: 1000,
                        data: function (params) {
                            // Query parameters will be ?search=[term]&type=public
                            return {
                                search: params.term,
                                cod_cliente: $(e).find("select[name='" + filtro + "cod_cliente']").val(),
                                page: params.page || 1
                            };
                        },

                    },
                });
            $(e)
                .find("select[name='" + filtro + "cod_grupos']")
                .select2({
                    dropdownParent: $(e),
                    ajax: {
                        url: TaxiReis.functions.core.getURL() + "/api/v2/autocomplete/grupos",
                        dataType: "json",
                        delay: 1000,
                        query: function (q) {
                            const pageLength = 50;

                            // Get a page sized slice of data from the results of filtering the data set.
                            const paged = this.data.slice((q.page - 1) * pageLength, q.page * pageLength);

                            q.callback({
                                results: paged,
                                more: this.data.length >= q.page * pageLength
                            });
                        },
                        data: function (params) {
                            // Query parameters will be ?search=[term]&page=[page]
                            return {
                                search: params.term,
                                page: params.page || 1,
                                cod_solicitante: $(".modal select[name='cod_solicitante']").val(),
                            };
                        },
                        cache: !0,
                    },
                });
            $(e)
                .find("select[name='" + filtro + "cod_fatura']")
                .select2({
                    dropdownParent: $(e),
                    ajax: {
                        url: TaxiReis.functions.core.getURL() + "/api/v2/autocomplete/faturas",
                        dataType: "json",
                        delay: 1000,
                        cache: !0,
                        data: function (params) {
                            return {
                                search: params.term,
                                page: params.page || 1
                            };
                        },
                    },

                });
            $(e)
                .find("select[name='" + filtro + "cod_empresa']")
                .select2({
                    dropdownParent: $(e),
                    ajax: {
                        url: TaxiReis.functions.core.getURL() + "/api/v2/autocomplete/empresas",
                        dataType: "json",
                        delay: 1000,
                        processResults: function (e) {
                            return {
                                results: $.map(e, function (e) {
                                    return {
                                        text: e.nome_fantasia,
                                        id: e.id
                                    };
                                }),
                            };
                        },
                        cache: !0,
                    },
                }).on('select2:open', () => {
                    $(".select2-results:not(:has(a))").append('<a  class="btn btn-primary add_new_form" id="empresas" style="padding: 6px;height: 20px;display: inline-table;width: 100%;">Adicionar Empresa</a>')
                });
            $(e).find("select[name='" + filtro + "tip_contrato']").select2({ dropdownParent: $(e) });
            $(e).find("select[name='" + filtro + "prestadora']").select2({ dropdownParent: $(e) });
            $(e).find("select[name='" + filtro + "cod_itinerario']")
                .select2({
                    dropdownParent: $(e),
                    ajax: {
                        url: TaxiReis.functions.core.getURL() + "/api/v2/autocomplete/itinerarios",
                        dataType: "json",
                        delay: 1000,
                        data: function (params) {
                            // Query parameters will be ?search=[term]&type=public
                            let max_pass_carro = $(e).find("#cod_cliente").select2('data')[0].max_pass_carro ?? 4;

                            let tipo_veiculo = $("#json_table table").dataTable().api().rows().count() > max_pass_carro ? 8 : null;


                            return {
                                search: params.term,
                                cod_cliente: $(e).find("select[name='" + filtro + "cod_cliente']").val(),
                                cod_tipo_veiculo: tipo_veiculo,
                                page: params.page || 1
                            };
                        },

                    },
                });
            $(e)
                .find("select[name='" + filtro + "cod_veiculo']")
                .select2({
                    dropdownParent: $(e),
                    ajax: {
                        url: TaxiReis.functions.core.getURL() + "/api/v2/autocomplete/veiculos",
                        dataType: "json",
                        delay: 1000,
                    },
                });
            $(e)
                .find("select[name='tipo_servico']")
                .select2({
                    dropdownParent: $(e),
                    ajax: {
                        url: TaxiReis.functions.core.getURL() + "/api/v2/autocomplete/tipo_servico",
                        dataType: "json",
                        delay: 1000,
                        processResults: function (e) {
                            return {
                                results: $.map(e, function (e) {
                                    return {
                                        text: e.nome,
                                        id: e.id
                                    };
                                }),
                            };
                        },
                        cache: !0,
                    },
                });
            $(e)
                .find("select[name='tipo_veiculo']")
                .select2({
                    dropdownParent: $(e),
                    ajax: {
                        url: TaxiReis.functions.core.getURL() + "/api/v2/autocomplete/tipo_veiculos",
                        dataType: "json",
                        delay: 1000,
                        processResults: function (e) {
                            return {
                                results: $.map(e, function (e) {
                                    return {
                                        text: e.tipo,
                                        id: e.id
                                    };
                                }),
                            };
                        },
                        cache: !0,
                    },
                });
            $(e).find(".make-sl2").select2({
                dropdownParent: $(e)
            });
            $(e)
                .find("select[name='" + filtro + "cod_base']")
                .select2({
                    dropdownParent: $(e),
                    ajax: {
                        url: TaxiReis.functions.core.getURL() + "/api/v2/autocomplete/bases",
                        dataType: "json",
                        delay: 1000,
                        processResults: function (e) {
                            return {
                                results: $.map(e, function (e) {
                                    return {
                                        text: e.nome,
                                        id: e.id
                                    };
                                }),
                            };
                        },
                        cache: !0,
                    },
                });
            $(e).find("select").on("select2:open", function () {
                let current = $(e);
                current.attr("IsSelectOpen", "true");
            })
            if ($(e).hasClass("modal")) {
                $(e).find("select").on("select2:open", function () {
                    let current = $(e);
                    current.attr("IsSelectOpen", "true");

                })

                $(e).find("select").on("select2:close", function () {
                    let current = $(e);
                    setTimeout(function () {
                        current.attr("IsSelectOpen", "false");
                    }, 500);
                })
            }

            $(document).on('select2:open', () => {
                document.querySelector('.select2-search__field').focus();
            });

            $(document).find(".select2-hidden-accessible").on("change", function () {
                let cur_el = $(this);
                let button = $("#button_" + cur_el.attr("id"))
                button.off("click")
                console.info(cur_el.attr("typ"))
                if (typeof button.attr("typ") !== "undefined" && cur_el.attr("typ") !== null) {
                    if (cur_el.val() !== null) {
                        button.html("<i class=\"fa fa-edit\"></i>")
                        button.on("click", function () {
                            TaxiReis.EditarItem(cur_el.val(), button.attr("typ"))
                        })
                    } else {
                        button.html("<i class=\"fa fa-plus\"></i>")
                        button.on("click", function () {
                            TaxiReis.AddItem(button.attr("typ"))
                        })
                    }
                }
            })

            $(document).find(".select2-hidden-accessible").trigger("change");
        },
        AdicionarItem: {
            fatura: function (id = null) {
                if (id === null)
                    return
                $.LoadingOverlay("show"),
                    $.ajax({
                        type: "GET",
                        url: "/api/v2/forms/faturas/add",
                        dataType: "json",
                        success: function (t) {
                            modals.modal({
                                body: t.html,
                                size: "xl",
                                footer: '<button type="button" class="btn btn-danger" data-bs-dismiss="modal">Cancelar</button><button type="submit" class="btn btn-success">Adicionar</button>',
                                onCreate: function (e) {
                                    let cur_modal = e;
                                    $(e.element).find("#formAdd").append("<input type='hidden' name='cod_solicitacao' value='" + id + "' id='cod_solicitacao'>");
                                    TaxiReis.MaskAll(),
                                        $(e.element).on("click", "button[type='submit']", function (a) {
                                            a.preventDefault(),
                                                $.ajax({
                                                    url: "api/v2/faturas",
                                                    type: "post",
                                                    dataType: "json",
                                                    data: $(e.element).find("form").serialize(),
                                                    success: function (a) {
                                                        console.info(JSON.stringify($(e.element).find("form").serialize()));
                                                        $(e).modal('hide');
                                                        const o = {
                                                            cod_fatura: a.data.id
                                                        };
                                                        $.ajax({
                                                            url: "api/v2/solicitacoes/" + id,
                                                            type: "patch",
                                                            dataType: "json",
                                                            data: o,
                                                            success: function (e) {
                                                                toastr.success("Fatura adicionada com sucesso");
                                                            },
                                                            error: function (data) {
                                                                const response = data.responseJSON;
                                                                if (typeof response.errors !== 'undefined' && Object.keys(response.errors).length > 0) {
                                                                    $.each(response.errors, function (k, v) {
                                                                        if (v.length > 0) {
                                                                            $.each(v, function (key, value) {
                                                                                toastr.error(value);
                                                                            })
                                                                        } else {
                                                                            toastr.error(v)
                                                                        }
                                                                    })
                                                                    TaxiReis.MaskAll();
                                                                } else {
                                                                    if (typeof response.message !== 'undefined') {
                                                                        toastr.error(response.message)
                                                                    } else {
                                                                        toastr.error(response)
                                                                    }
                                                                }
                                                            }

                                                        });
                                                    },

                                                });
                                        }),
                                        TaxiReis.SetupSelects(cur_modal.element);
                                },
                            });
                        },
                        complete: function () {
                            $.LoadingOverlay("hide");
                        },
                    });
            }
        },
        ToggleActiveItem: function (id, ativo, type = TaxiReis.getMetaModal(), onsuccess = function () {
            $.each(window.LaravelDataTables, function (k, v) {
                v.ajax.reload();
            })
        }) {
            $.ajax({
                type: "PATCH",
                url: "/api/v2/" + type + "/" + id,
                data: { "ativo": ativo === true ? 1 : 0 },
                success: function (e) {
                    onsuccess();
                    toastr.success("Atualizado com sucesso")
                },
                error: function (data) {
                    const response = data.responseJSON;
                    if (typeof response.errors !== 'undefined' && Object.keys(response.errors).length > 0) {
                        $.each(response.errors, function (k, v) {
                            if (v.length > 0) {
                                $.each(v, function (key, value) {
                                    toastr.error(value);
                                })
                            } else {
                                toastr.error(v)
                            }
                        })
                        TaxiReis.MaskAll();
                    } else {
                        if (typeof response.message !== 'undefined') {
                            toastr.error(response.message)
                        } else {
                            toastr.error(response)
                        }
                    }
                }
            })
        },
        AddItem: function (id = TaxiReis.getMetaModal(), extraparams = null) {
            const t = id;
            $.LoadingOverlay("show"),
                $.ajax({
                    type: "GET",
                    url: "/api/v2/forms/" + t + "/add",
                    data: extraparams,
                    success: function (data) {
                        if (t === "solicitacoes") {
                            data.html = data.html + "<div id='json_table' class='table-responsive'><table id='passageiros_table' class='table'> <thead> <tr> <th scope='col'>#</th> <th scope='col'>Passageiro</th><th scope='col'>Tel.</th><th scope='col'>Origem</th><th scope='col'>Destino</th> <th scope='col'>C. Custo</th> </tr></thead><tbody></tbody></table></div><div style='text-align: right;'>* Clique com o direito sobre a tabela para as opções</div>";
                        }
                        if (data.error == true) {
                            toastr.error(data.message)
                        }
                        modals.modal({
                            body: data.html,
                            size: "xl",
                            footer: '<button type="button" class="btn btn-danger" data-bs-dismiss="modal">Cancelar</button><button type="submit" class="btn btn-success">Adicionar</button>',
                            onCreate: function (e) {
                                const calcularTotal = function () {
                                    const formatter = new Intl.NumberFormat("pt-br", {
                                        style: "currency",
                                        currency: "BRL"
                                    });

                                    let inputs = $(e.element).find("#km_extras,#horas_paradas,#despesas_adicionais,#estacionamento,#pedagio,#barcas");
                                    let val_iti;
                                    try {
                                        val_iti = parseFloat(data.data.itinerarios.valor);
                                    } catch (e) {
                                        val_iti = 0;

                                    }
                                    let value_carro = val_iti;
                                    let value_van = val_iti;
                                    let hasCarro = false;
                                    let hasVan = false;
                                    let value = "";
                                    $.each(inputs, function (k, v) {

                                        if (v.id === "km_extras" || v.id === "horas_paradas") {
                                            let val_carro = 0;
                                            let val_van = 0;
                                            if (typeof v.getAttribute("carro") !== "undefined" && v.getAttribute("carro") !== null) {
                                                if (v > 0) {
                                                    val_carro = parseFloat(v.getAttribute("carro"));
                                                }
                                            }
                                            if (typeof v.getAttribute("van") !== "undefined" && v.getAttribute("van") !== null) {
                                                if (v > 0) {
                                                    val_van = parseFloat(v.getAttribute("van"));
                                                }
                                            }
                                            value_carro = parseFloat(value_carro) + val_carro;
                                            value_van = parseFloat(value_van) + val_van;
                                            hasCarro = v.getAttribute("carro") != null;
                                            hasVan = v.getAttribute("van") != null;
                                        } else {
                                            let val = 0;
                                            if (v.value > 0) {
                                                val = parseFloat(v.value);

                                                value_carro = parseFloat(value_carro) + val;
                                                value_van = parseFloat(value_van) + val;
                                            }
                                        }
                                    })

                                    if (hasCarro && hasVan) {
                                        value = `Carro: ${formatter.format(value_carro)} | Van: ${formatter.format(value_van)}`
                                    } else {
                                        value = hasCarro ? formatter.format(value_carro) : formatter.format(value_van);
                                    }
                                    $(e.element).find("#valor_total").val(value)
                                };

                                $(e.element).on("click", "button[type='submit']", function (a) {
                                    $(e.element).LoadingOverlay("show");
                                    let form_serialized = $(e.element).find('form').serializeArray();
                                    let form_data = {};
                                    let permissoes = $("#lista_permissoes");

                                    let perms = [];
                                    $.each(permissoes.find("input:checked"), function () {
                                        perms.push(this.name)
                                    })
                                    if (permissoes.length > 0) {
                                        form_data["permissions"] = perms;
                                    }
                                    let passageiros_table = $(e.element).find("#json_table table");
                                    if (passageiros_table.length > 0 && passageiros_table.dataTable().api().rows().data().toArray().length > 0) {
                                        form_data["passageiros"] = passageiros_table.dataTable().api().rows().data().toArray();
                                    }


                                    let inputs = $(e.element).find("#formAdd input,textarea");
                                    let selects = $(e.element).find("#formAdd select");
                                    $.each(inputs, function (k, v) {
                                        let value = $(v).val()
                                        let name = $(v).attr('name');
                                        let type = $(v).is('select') === true ? "select" : $(v).attr('type')
                                        switch (type) {
                                            case "number":
                                                value = parseFloat(value);
                                                if (!isNaN(value)) {
                                                    form_data[name] = value;
                                                }
                                                break;
                                            case "checkbox":
                                                form_data[name] = $(v).is(":checked") === true ? 1 : 0;
                                                break;
                                            case "select":
                                                if (name === "cod_grupos") {
                                                    form_data[name] = $(v).val();
                                                } else {
                                                    let valu = form_data[name] = $(v).val();
                                                    if (valu !== null && !isNaN(valu)) {
                                                        form_data[name] = valu;
                                                    } else {
                                                        form_data[name] = null;
                                                    }
                                                }
                                                break;
                                            default:
                                                form_data[name] = $(v).val();
                                                break;
                                        }
                                    })
                                    $.each(selects, function (k, v) {
                                        let value = isNaN($(v).val()) ? $(v).val() : parseInt($(v).val())
                                        let name = $(v).attr('name');
                                        if (value !== undefined && value !== null && value !== "") {
                                            form_data[name] = value;
                                        } else {
                                            form_data[name] = null;
                                        }
                                    })
                                    if (form_data["data_programada"]) {
                                        form_data["data_programada"] = form_data["data_programada"] + ' ' + form_data["hora_programada"];
                                    }
                                    if (form_data["data_chegada"]) {
                                        form_data["data_hora_chegada"] = form_data["data_chegada"] + ' ' + form_data["hora_chegada"];
                                    }
                                    if (extraparams != null) {
                                        form_data["extra_params"] = extraparams;
                                    }


                                    a.preventDefault(),
                                        $.ajax({
                                            url: "api/v2/" + t,
                                            type: "post",
                                            data: JSON.stringify(form_data),
                                            contentType: 'application/json',
                                            processData: false,
                                            dataType: 'json',
                                            success: function (a) {
                                                $(e.element).modal('hide'),
                                                    console.info(a),
                                                    $("#crud-table").DataTable().ajax.reload(),
                                                    $(".modal-backdrop").remove(),
                                                    $("body").removeClass("modal-open"),
                                                    $("#addModal").modal('hide');
                                                if (a.error === true) {
                                                    toastr.success(a.message);

                                                } else {
                                                    toastr.success(a.message);
                                                }
                                                "solicitacoes" === t &&
                                                    modals.confirm({
                                                        title: "Gostaria de adicionar a fatura?",
                                                        body: "Gostaria de adicionar a fatura agora?",
                                                        textTrue: "Sim",
                                                        textFalse: "Não",
                                                        onSubmit: function (e) {
                                                            if (e === true) {
                                                                TaxiReis.functions.faturas.adicionar(a.data.id);
                                                            }
                                                        },
                                                        onDispose: function () {
                                                            console.log("The confirm dialog vanished");
                                                        },
                                                    });
                                            },
                                            error: function (data) {
                                                const response = data.responseJSON;
                                                if (typeof response.errors !== 'undefined' && Object.keys(response.errors).length > 0) {
                                                    $.each(response.errors, function (k, v) {
                                                        if (v.length > 0) {
                                                            $.each(v, function (key, value) {
                                                                toastr.error(value);
                                                            })
                                                        } else {
                                                            toastr.error(v)
                                                        }
                                                    })
                                                    TaxiReis.MaskAll();
                                                } else {
                                                    if (typeof response.message !== 'undefined') {
                                                        toastr.error(response.message)
                                                    } else {
                                                        toastr.error(response)
                                                    }
                                                }
                                            },
                                            complete: function () {
                                                $(e.element).LoadingOverlay("hide");
                                            }

                                        });
                                }),
                                    TaxiReis.SetupSelects($(e.element));
                                switch ((TaxiReis.MaskAll(), TaxiReis.getMetaModal())) {
                                    case "solicitacoes":
                                        $("#cod_itinerario").on("change", function () {
                                            $("#json_table table").dataTable().api().rows().invalidate()
                                        })
                                        let pass = [];
                                        if (typeof data.data !== "undefined" && data.data !== null) {
                                            pass = data.data.passageiros;
                                        }
                                        $(e.element).find("#json_table table").passageiros_table(pass, e, $(e).find("#cod_itinerario"))
                                        break;
                                    case "faturas":
                                        calcularTotal();
                                        alert("teste")
                                        $(e.element).find("#km_extras,#horas_paradas,#despesas_adicionais,#estacionamento,#pedagio,#barcas").on('change', function () {
                                            calcularTotal();
                                        });
                                        const formatter = new Intl.NumberFormat('pt-BR', {
                                            style: 'currency',
                                            currency: 'BRL',
                                        });

                                        $(e.element).find("#horas_paradas").change(function () {
                                            let tarifas = $(e.element).find("#tarifas_table").dataTable().api().data().toArray();
                                            let taxa_van = null;
                                            let taxa_carro = null;
                                            let v_ = this.value
                                            if (typeof tarifas === "undefined" || tarifas === null || tarifas.length === 0) {
                                                taxa_carro = taxa_carro = v_ * 1
                                            }
                                            $.each(tarifas, function (k, v) {
                                                if (v.cod_tipo_veiculo === 6) {
                                                    taxa_carro = v_ * v.valor_hora_parada;
                                                } else if (v.cod_tipo_veiculo === 8) {
                                                    taxa_van = v_ * v.valor_hora_parada;
                                                }
                                            })

                                            let v_carro = formatter.format(taxa_carro);
                                            let v_van = formatter.format(taxa_van)
                                            if (taxa_carro === null) {
                                                this.removeAttribute("carro")
                                            } else {
                                                this.setAttribute("carro", taxa_carro)
                                            }
                                            if (taxa_van === null) {
                                                this.removeAttribute("van")
                                            } else {
                                                this.setAttribute("van", taxa_van)
                                            }

                                            let ht = "";
                                            if (taxa_van != null && taxa_carro != null) {
                                                ht = "<small orignal_val='" + taxa_carro + "' id='taxa_carro'><i class=\"mdi mdi-car-side\" style=\"padding-right: 5px;\"></i>" + v_carro + "</small>"
                                                ht = ht + "<small orignal_val='" + taxa_van + "' id='taxa_van'><i class=\"mdi mdi-van-passenger\" style=\"padding-right: 5px;\"></i>  " + v_van + "  </small>"
                                            } else {
                                                if (taxa_carro != null) {
                                                    ht = "<small orignal_val='" + taxa_carro + "' id='taxa_van'><i class=\"mdi mdi-car-side\" style=\"padding-right: 5px;\"></i>  " + v_carro + "  </small>"
                                                }
                                                if (taxa_van != null) {
                                                    ht = "<small orignal_val='" + taxa_van + "' id='taxa_van'><i class=\"mdi mdi-van-passenger\" style=\"padding-right: 5px;\"></i> " + v_van + "  </small>"
                                                }
                                            }
                                            $(e.element).find("#horas_paradas_right").html(ht)
                                        })
                                        $(e.element).find("#km_extras").change(function () {
                                            let tarifas = $(e.element).find("#tarifas_table").dataTable().api().data().toArray();
                                            let taxa_van = null;
                                            let taxa_carro = null;
                                            let v_ = this.value
                                            if (typeof tarifas === "undefined" || tarifas === null || tarifas.length === 0) {
                                                taxa_carro = taxa_carro = v_ * 1
                                            }
                                            console.info(tarifas)
                                            $.each(tarifas, function (k, v) {
                                                if (v.cod_tipo_veiculo === 6) {
                                                    taxa_carro = v_ * v.valor_km_excedido;
                                                } else if (v.cod_tipo_veiculo === 8) {
                                                    taxa_van = v_ * v.valor_km_excedido;
                                                }
                                            })
                                            if (taxa_carro === null) {
                                                this.removeAttribute("carro")
                                            } else {
                                                this.setAttribute("carro", taxa_carro)
                                            }
                                            if (taxa_van === null) {
                                                this.removeAttribute("van")
                                            } else {
                                                this.setAttribute("van", taxa_van)
                                            }
                                            let v_carro = formatter.format(taxa_carro);
                                            let v_van = formatter.format(taxa_van)
                                            let ht = "";
                                            if (taxa_van != null && taxa_carro != null) {
                                                ht = "<small><i class=\"mdi mdi-car-side\" style=\"padding-right: 5px;\"></i>  " + v_carro + "  </small>"
                                                ht = ht + "<small><i class=\"mdi mdi-van-passenger\" style=\"padding-right: 5px;\"></i>  " + v_van + "  </small>"
                                            } else {
                                                if (taxa_carro != null) {
                                                    ht = "<small><i class=\"mdi mdi-car-side\" style=\"padding-right: 5px;\"></i>  " + v_carro + "  </small>"
                                                }
                                                if (taxa_van != null) {
                                                    ht = "<small><i class=\"mdi mdi-van-passenger\" style=\"padding-right: 5px;\"></i>  " + v_van + "  </small>"
                                                }
                                            }
                                            $(e.element).find("#km_extras_right").html(ht)
                                        })

                                        break;
                                }

                            },
                        });
                    },
                    error: function (data) {
                        const response = data.responseJSON;
                        if (typeof response.errors !== 'undefined' && Object.keys(response.errors).length > 0) {
                            $.each(response.errors, function (k, v) {
                                if (v.length > 0) {
                                    $.each(v, function (key, value) {
                                        toastr.error(value);
                                    })
                                } else {
                                    toastr.error(v)
                                }
                            })
                            TaxiReis.MaskAll();
                        } else {
                            if (typeof response.message !== 'undefined') {
                                toastr.error(response.message)
                            } else {
                                toastr.error(response)
                            }
                        }
                    },
                    complete: function () {
                        $.LoadingOverlay("hide");
                    },
                });
        },
        DeletarSolicitacao: function (id) {
            modals.confirm({
                title: "Gostaria de deletar essa solicitação?",
                body: "Realmente gostia de deletar a solicitação #" + id + "? ",
                textTrue: "Sim",
                textFalse: "Não",
                onSubmit: function (result) {
                    if (result) {
                        $.ajax({
                            type: "DELETE",
                            url: "/api/v2/solicitacoes/" + id,
                            dataType: "json",
                            success: function (data) {
                                if (!data.error)
                                    toastr["success"]("Solicitação removida com sucesso");
                                else
                                    toastr["error"]("Erro: " + data.message);
                                $("table").DataTable().ajax.reload();
                            },
                            error: function (data) {
                                const response = data.responseJSON;
                                if (typeof response.errors !== 'undefined' && Object.keys(response.errors).length > 0) {
                                    $.each(response.errors, function (k, v) {
                                        if (v.length > 0) {
                                            $.each(v, function (key, value) {
                                                toastr.error(value);
                                            })
                                        } else {
                                            toastr.error(v)
                                        }
                                    })
                                    TaxiReis.MaskAll();
                                } else {
                                    if (typeof response.message !== 'undefined') {
                                        toastr.error(response.message)
                                    } else {
                                        toastr.error(response)
                                    }
                                }
                            }

                        });
                    }
                },
                onDispose: function () {

                },
            });

        },
        EditarItem: function (id, type = TaxiReis.getMetaModal(), parent = null) {
            this.id = id;
            $.LoadingOverlay("show"),
                $.ajax({
                    type: "GET",
                    url: "/api/v2/forms/" + type + "/edit/" + this.id,
                    dataType: "json",
                    success: function (data) {
                        if ((type === "solicitacoes")) {
                            let tHead = "<thead counter-id='1'> <tr counter-id='1'> <th scope='col' counter-id='1'>#</th> <th scope='col' counter-id='1'>Passageiro</th><th scope='col'>Tel</th><th scope='col' counter-id='1'>Origem</th><th scope='col' counter-id='1'>Destino</th><th scope='col' counter-id='1'>C. Custo</th> <th scope='col' counter-id='1'>Valor</th> </tr></thead>";
                            let fatura_html = data.data.fatura != null ? `<div style="text-align: center;"><h4>Solicitação: #<a id="cod_solicitacao_div">${data.data.id}</a> - Fatura: #${data.data.fatura.id}<button type='button' class='btn btn-xs btn-primary'  style="line-height: 1.5rem;padding: .6rem 1rem; margin-left: 6px;" onclick='TaxiReis.EditarItem(${data.data.fatura.id},"faturas")'><i class="mdi mdi-eye"></i>Ver Fatura</button></h4></div>` : `<div style="text-align: center; "><h4>Solicitação:  #<a id="cod_solicitacao_div">${data.data.id}</a> - Fatura: <a onclick="this.faturas.adicionar(${data.data.id})"><span class="label label-primary">Gerar Fatura</span></a></h4></div>`;
                            data.html = fatura_html + data.html + "<div id='json_table' class='table-responsive'><table class='table' counter-id='1'>" + tHead + "<tbody></tbody></table></div><div style='text-align: right;'>* Clique com o direito sobre a tabela para as opções</div>"

                        }
                        if ((type === "faturas")) {
                            let fatura_html = `<div style="text-align: center;"><h4>Fatura: #<a id="cod_solicitacao_div">${id}</a></h4></div>`;
                            data.html = fatura_html + data.html
                        }
                        let solicitacao = null;
                        let solicitacoes = null;
                        if (data.data) {
                            solicitacao = data.data[0];
                            solicitacoes = data.data;
                        }
                        if (solicitacoes !== null && solicitacoes) {
                            for (let i = 0; i < solicitacoes.length; i++) {
                                if (i === 0) {
                                    solicitacao = solicitacoes[i];
                                    continue;
                                }
                                if (typeof solicitacoes[i]["itinerarios"] !== "undefined" && solicitacoes[i]["itinerarios"] !== null && typeof solicitacoes[i]["itinerarios"]["valor"] !== "undefined" && solicitacoes[i]["itinerarios"]["valor"] !== null) {
                                    if (solicitacoes[i]["valor_itinerario"] > solicitacao["valor_itinerario"]) {
                                        solicitacao = solicitacoes[i];
                                    }
                                } else {
                                    if (typeof solicitacao[i]["fatura"] !== "undefined" && solicitacao[i]["fatura"] !== null) {

                                    }
                                }
                            }
                        }
                        let fatura = null;
                        if (data.data) {
                            if (data.data.fatura) {
                                fatura = data.data.fatura;
                            }
                        }
                        let buttons = '<button type="button" class="btn btn-danger" data-bs-dismiss="modal">Cancelar</button>'
                        if (type === "solicitacoes") {
                            buttons = buttons + "<button type=\"button\" class=\"btn btn-success\" id=\"copiar_solicitacao\">Copiar</button>"
                        }
                        buttons = buttons + "<button type=\"submit\" class=\"btn btn-success\" >Atualizar</button>"
                        modals.modal({
                            title: "",
                            body: data.html,
                            size: "xl",

                            footer: buttons,
                            onCreate: function (modal) {
                                const calcularTotal = function () {
                                    const formatter = new Intl.NumberFormat("pt-br", {
                                        style: "currency",
                                        currency: "BRL"
                                    });

                                    let inputs = $(modal.element).find("#km_extras,#horas_paradas,#despesas_adicionais,#estacionamento,#pedagio,#barcas");
                                    let val_iti;
                                    let isKmLivre = false;
                                    try {
                                        if (solicitacao.itinerarios !== null) {
                                            val_iti = parseFloat(solicitacao.itinerarios.valor);
                                        } else {
                                            isKmLivre = true;
                                            val_iti = parseFloat($(modal.element).find("#odometro_chegada").val()) - parseFloat($(modal.element).find("#odometro_saida").val());
                                        }
                                    } catch (e) {
                                        val_iti = 0;
                                        console.info(e)
                                    }
                                    let value_carro = val_iti;
                                    let value_van = val_iti;
                                    if (isKmLivre === true) {
                                        let tarifas = $(modal.element).find("#tarifas_table").dataTable().api().rows().data().toArray();
                                        let tarifa_v = null;
                                        let tarifa_c = null;
                                        $.each(tarifas, function (k, v) {
                                            if (v.cod_tipo_veiculo == 8) {
                                                tarifa_v = v;
                                            }

                                            if (v.cod_tipo_veiculo == 6) {
                                                tarifa_c = v;
                                            }
                                        })

                                        if (tarifa_v !== null) {
                                            console.info(tarifa_v)
                                            value_van = value_van * parseFloat(tarifa_v.valor_km_livre)
                                        }

                                        if (tarifa_c !== null) {
                                            console.info(tarifa_c)

                                            value_carro = value_carro * parseFloat(tarifa_c.valor_km_livre)
                                        }
                                    }

                                    let hasCarro = false;
                                    let hasVan = false;
                                    let value = "";
                                    $.each(inputs, function (k, v) {
                                        if (v.id === "km_extras" || v.id === "horas_paradas") {
                                            let val_carro = 0;
                                            let val_van = 0;
                                            if (typeof v.getAttribute("carro") !== "undefined" && v.getAttribute("carro") !== null) {
                                                if (v.value > 0) {
                                                    val_carro = parseFloat(v.getAttribute("carro"));
                                                }
                                            }
                                            if (typeof v.getAttribute("van") !== "undefined" && v.getAttribute("van") !== null) {
                                                if (v.value > 0) {
                                                    val_van = parseFloat(v.getAttribute("van"));
                                                }
                                            }
                                            value_carro = parseFloat(value_carro) + val_carro;
                                            value_van = parseFloat(value_van) + val_van;
                                            hasCarro = v.getAttribute("carro") != null;
                                            hasVan = v.getAttribute("van") != null;
                                        } else {
                                            let val = 0;
                                            if (v.value > 0) {
                                                val = parseFloat(v.value);

                                                value_carro = parseFloat(value_carro) + val;
                                                value_van = parseFloat(value_van) + val;
                                            }
                                        }
                                    })

                                    if (hasCarro && hasVan) {
                                        value = `Carro: ${formatter.format(value_carro)} | Van: ${formatter.format(value_van)}`
                                    } else {
                                        value = hasCarro ? formatter.format(value_carro) : formatter.format(value_van);
                                    }
                                    $(modal.element).find("#valor_total").val(value)
                                };
                                $(modal.element).find("#odometro_saida").on("change", function () {
                                    calcularTotal()
                                });

                                $(modal.element).find("#odometro_chegada").on("change", function () {
                                    calcularTotal()
                                });
                                $(modal.element).find("#tarifas_table").on("draw", function () {
                                    calcularTotal();
                                })
                                // create event handler for form submit and handle values
                                TaxiReis.MaskAll();
                                TaxiReis.SetupSelects(modal.element);
                                $(modal.element).find("#passageiros_table").DataTable({
                                    responsive: true,
                                    autoWidth: false,

                                })

                                if (type !== "solicitacoes") {


                                    $("#solicitacoes_table").DataTable({
                                        data: solicitacoes,
                                        "columns": [
                                            {
                                                data: "n_chamado",
                                                name: "n_chamado",
                                                title: "Chamado",
                                                orderable: true,
                                                searchable: true,
                                                responsivePriority: 1
                                            },
                                            {
                                                data: "data_programada",
                                                name: "data_programada",
                                                title: "Data Programada",
                                                orderable: true,
                                                searchable: false,
                                                responsivePriority: 1
                                            },
                                            {
                                                data: "cliente.nome_fantasia",
                                                name: "cliente",
                                                title: "Cliente",
                                                orderable: true,
                                                searchable: false,
                                                responsivePriority: 1
                                            },
                                            {
                                                data: "solicitantes.nome",
                                                name: "solicitantes",
                                                title: "Solicitante",
                                                orderable: true,
                                                searchable: false,
                                                responsivePriority: 1
                                            },
                                            {
                                                data: "itinerarios.itinerario",
                                                name: "itinerarios.itinerario",
                                                title: "Itinerario",
                                                orderable: true,
                                                searchable: false,
                                                responsivePriority: 1
                                            },
                                        ],
                                        order: [
                                            [0, "desc"]
                                        ],
                                        responsive: true,
                                        autoWidth: false,
                                        dom: "<\"toolbar\">Blfrtip",
                                        buttons: [],
                                    });
                                }
                                $(modal.element).find("#cod_tipo_veiculo,#cod_veiculo").on("change", function () {
                                    $(modal.element).find("#tarifas_table").dataTable().api().ajax.reload()

                                })
                                $("#tarifas_table").DataTable({
                                    serverSide: true,
                                    processing: true,
                                    ajax: {
                                        url: TaxiReis.functions.core.getURL() + "/tarifas",
                                        data: function (d) {
                                            const indexed_array = {};
                                            let cod_tipo_veh = 6;
                                            try {
                                                if (solicitacao.itinerarios !== null) {
                                                    cod_tipo_veh = solicitacao.itinerarios.cod_tipo_veiculo;
                                                }
                                            } catch (e) {
                                                console.info("Solicitação não contem itinerario")
                                                cod_tipo_veh = $(modal.element).find("#cod_veiculo").val();
                                            }

                                            indexed_array["cod_cliente"] = -1;
                                            if (typeof solicitacao !== "undefined" && solicitacao !== null) {
                                                if (solicitacao.cod_cliente) {
                                                    indexed_array["cod_cliente"] = solicitacao.cod_cliente;
                                                }
                                            }

                                            indexed_array["cod_veiculo"] = $(modal.element).find("#cod_veiculo").val();
                                            indexed_array["cod_tipo_veiculo"] = cod_tipo_veh;
                                            d.filters = indexed_array;
                                        }
                                    },
                                    columns: [
                                        {
                                            data: "id",
                                            name: "id",
                                            title: "Id",
                                            orderable: true,
                                            searchable: true,
                                            defaultContent: ""
                                        },
                                        {
                                            data: "tarifa",
                                            name: "tarifa",
                                            title: "Tarifa",
                                            orderable: true,
                                            searchable: true,
                                            defaultContent: ""
                                        },
                                        {
                                            data: "contrato.numero",
                                            name: "contrato.numero",
                                            title: "Contrato",
                                            orderable: true,
                                            searchable: true,
                                            defaultContent: ""
                                        },
                                        {
                                            data: "cod_tipo_veiculo",
                                            name: "cod_tipo_veiculo",
                                            title: "Tipo de Veiculo",
                                            orderable: true,
                                            searchable: true,
                                            defaultContent: "",
                                            render: function (data, type, full, meta) {
                                                return function () {
                                                    const tipo_veh = JSON.parse('{"6":"Carro","8":"Van"}');
                                                    return tipo_veh[data];
                                                };
                                            },
                                        },
                                        {
                                            data: "valor_hora_parada",
                                            name: "valor_hora_parada",
                                            title: "Valor Hora Parada",
                                            orderable: true,
                                            searchable: true,
                                            defaultContent: "",
                                            render: function (data, type, full, meta) {
                                                return function () {
                                                    var formatter = new Intl.NumberFormat("pt-br", {
                                                        style: "currency",
                                                        currency: "BRL"
                                                    });
                                                    return formatter.format(data);
                                                };
                                            },
                                        },
                                        {
                                            data: "valor_km_livre",
                                            name: "valor_km_livre",
                                            title: "Valor Km Livre",
                                            orderable: true,
                                            searchable: true,
                                            defaultContent: "",
                                            render: function (data, type, full, meta) {
                                                return function () {
                                                    var formatter = new Intl.NumberFormat("pt-br", {
                                                        style: "currency",
                                                        currency: "BRL"
                                                    });
                                                    return formatter.format(data);
                                                };
                                            },
                                        },
                                        {
                                            data: "valor_km_excedido",
                                            name: "valor_km_excedido",
                                            title: "Valor Km Excedido",
                                            orderable: true,
                                            searchable: true,
                                            defaultContent: "",
                                            render: function (data, type, full, meta) {
                                                return function () {
                                                    var formatter = new Intl.NumberFormat("pt-br", {
                                                        style: "currency",
                                                        currency: "BRL"
                                                    });
                                                    return formatter.format(data);
                                                };
                                            },
                                        },
                                        {
                                            data: "valor_diaria",
                                            name: "valor_diaria",
                                            title: "Valor Diaria",
                                            orderable: true,
                                            searchable: true,
                                            defaultContent: "",
                                            render: function (data, type, full, meta) {
                                                return function () {
                                                    var formatter = new Intl.NumberFormat("pt-br", {
                                                        style: "currency",
                                                        currency: "BRL"
                                                    });
                                                    return formatter.format(data);
                                                };
                                            },
                                        },
                                        {
                                            data: "valor_carro_disposicao",
                                            name: "valor_carro_disposicao",
                                            title: "Valor Carro Disposicao",
                                            orderable: true,
                                            searchable: true,
                                            defaultContent: "",
                                            render: function (data, type, full, meta) {
                                                return function () {
                                                    var formatter = new Intl.NumberFormat("pt-br", {
                                                        style: "currency",
                                                        currency: "BRL"
                                                    });
                                                    return formatter.format(data);
                                                };
                                            },
                                        },
                                        {
                                            data: "valor_mensal",
                                            name: "valor_mensal",
                                            title: "Valor Mensal",
                                            orderable: true,
                                            searchable: true,
                                            defaultContent: "",
                                            render: function (data, type, full, meta) {
                                                return function () {
                                                    var formatter = new Intl.NumberFormat("pt-br", {
                                                        style: "currency",
                                                        currency: "BRL"
                                                    });
                                                    return formatter.format(data);
                                                };
                                            },
                                        },
                                    ],
                                    responsive: true,
                                    autoWidth: false,
                                    dom: "<\"toolbar\"><'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>><'row'<'col-sm-12'tr>><'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",

                                    buttons: [],
                                    initComplete: function () {

                                        $.contextMenu({
                                            selector: "#" + this.attr("id") + " tbody tr",
                                            callback: function (t, i) {
                                                switch (t) {
                                                    case "edit":
                                                        TaxiReis.EditItem(i.$trigger.attr("id"), "tarifas");
                                                        break;
                                                    case "add":
                                                        TaxiReis.AddItem("tarifas", extra_params);
                                                        break;
                                                }
                                            },
                                            items: {
                                                add: {
                                                    name: "Adicionar",
                                                    icon: "fa-add"
                                                },
                                                edit: {
                                                    name: "Editar",
                                                    icon: "fa-edit"
                                                }
                                            },
                                        });
                                    },
                                });
                                $(modal.element).on('draw.dt', function () {
                                    $("#km_extras").trigger("change");
                                    $("#horas_paradas").trigger("change");
                                    calcularTotal();
                                });
                                $(modal.element).find("#cod_itinerario").on("change", function () {
                                    $("#json_table table").dataTable().api().rows().invalidate()
                                })
                                $(modal.element).on("click", "#copiar_solicitacao", function (event) {
                                    event.preventDefault();
                                    let form_data = {};
                                    let permissoes = $("#lista_permissoes");


                                    let perms = [];
                                    $.each(permissoes.find("input:checked"), function () {
                                        perms.push(this.name)
                                    })
                                    if (permissoes.length > 0) {
                                        form_data["permissions"] = perms;
                                    }


                                    let inputs = $(modal.element).find("#formAdd input,textarea");
                                    let selects = $(modal.element).find("#formAdd select");
                                    $.each(inputs, function (k, v) {
                                        let value = $(v).val()
                                        let name = $(v).attr('name');
                                        let type = $(v).is('select') === true ? "select" : $(v).attr('type')
                                        switch (type) {
                                            case "number":
                                                value = parseFloat(value);
                                                if (!isNaN(value)) {
                                                    form_data[name] = value;
                                                }
                                                break;
                                            case "checkbox":
                                                form_data[name] = $(v).is(":checked") === true ? 1 : 0;
                                                break;
                                            default:
                                                form_data[name] = $(v).val();
                                                break;
                                        }
                                    })
                                    $.each(selects, function (k, v) {
                                        let value = parseInt($(v).val())
                                        let name = $(v).attr('name');

                                        if (typeof $(v).val() === "object") {
                                            form_data[name] = $(v).val();

                                        } else if (!isNaN(value) && value !== null) {
                                            form_data[name] = value;
                                        } else {
                                            form_data[name] = null;
                                        }

                                    }
                                    );
                                    if (form_data["data_programada"]) {
                                        form_data["data_programada"] = form_data["data_programada"] + ' ' + form_data["hora_programada"];
                                    }
                                    if (form_data["data_chegada"]) {
                                        form_data["data_hora_chegada"] = form_data["data_chegada"] + ' ' + form_data["hora_chegada"];
                                    }
                                    if (type === "solicitacoes") {
                                        let passageiros_table = $(modal.element).find("#json_table table");
                                        if (passageiros_table.length > 0 && passageiros_table.dataTable().api().rows().data().toArray().length > 0) {
                                            form_data["passageiros"] = passageiros_table.dataTable().api().rows().data().toArray();
                                        }
                                    }
                                    $("#" + modal.id + " input:checkbox").map(function () {
                                        form_data[this.name] = this.checked ? parseInt("1") : parseInt("0");
                                    });

                                    TaxiReis.functions.solicitacoes.copiar.init(id, form_data)
                                });
                                $(modal.element).on("click", "button[type='submit']", function (event) {
                                    event.preventDefault();
                                    TaxiReis.UnMaskAll();

                                    let form_data = {};
                                    let permissoes = $("#lista_permissoes");


                                    let perms = [];
                                    $.each(permissoes.find("input:checked"), function () {
                                        perms.push(this.name)
                                    })
                                    if (permissoes.length > 0) {
                                        form_data["permissions"] = perms;
                                    }


                                    let inputs = $(modal.element).find("#formAdd input,textarea");
                                    let selects = $(modal.element).find("#formAdd select");
                                    $.each(inputs, function (k, v) {
                                        let value = $(v).val()
                                        let name = $(v).attr('name');
                                        let type = $(v).is('select') === true ? "select" : $(v).attr('type')
                                        switch (type) {
                                            case "number":
                                                value = parseFloat(value);
                                                if (!isNaN(value)) {
                                                    form_data[name] = value;
                                                }
                                                break;
                                            case "checkbox":
                                                form_data[name] = $(v).is(":checked") === true ? 1 : 0;
                                                break;
                                            default:
                                                form_data[name] = $(v).val();
                                                break;
                                        }
                                    })
                                    $.each(selects, function (k, v) {
                                        let value = isNaN($(v).val()) ? $(v).val() : parseInt($(v).val())
                                        let name = $(v).attr('name');
                                        if (typeof $(v).val() === "object") {
                                            form_data[name] = $(v).val();
                                        } else if (value !== undefined && value !== null && value !== "") {
                                            form_data[name] = value;
                                        } else {
                                            form_data[name] = null;
                                        }


                                    });
                                    if (form_data["data_programada"]) {
                                        form_data["data_programada"] = form_data["data_programada"] + ' ' + form_data["hora_programada"];
                                    }
                                    if (form_data["data_chegada"]) {
                                        form_data["data_hora_chegada"] = form_data["data_chegada"] + ' ' + form_data["hora_chegada"];
                                    }
                                    if (type === "solicitacoes") {
                                        let passageiros_table = $(modal.element).find("#json_table table");
                                        if (passageiros_table.length > 0 && passageiros_table.dataTable().api().rows().data().toArray().length > 0) {
                                            form_data["passageiros"] = passageiros_table.dataTable().api().rows().data().toArray();
                                        }
                                    }
                                    $("#" + modal.id + " input:checkbox").map(function () {
                                        form_data[this.name] = this.checked ? parseInt("1") : parseInt("0");
                                    });

                                    if (type === "faturas") {
                                        form_data['verificar_motorista'] = true;

                                    }
                                    const sendData = () => {
                                        $.ajax({
                                            url: 'api/v2/' + type + '/' + id,
                                            type: 'patch',
                                            data: JSON.stringify(form_data),
                                            contentType: 'application/json',
                                            processData: false,
                                            dataType: 'json',
                                            success: function (data) {
                                                console.info(data);

                                                $(".modal-backdrop").remove();
                                                $('body').removeClass('modal-open');
                                                if (data.error === true) {
                                                    toastr.error(data.message);
                                                } else {
                                                    toastr.success(data.message);
                                                }
                                                $(modal.element).modal('hide');
                                                $("#crud-table").DataTable().ajax.reload();
                                                $('#itinerarios_table').DataTable().ajax.reload();
                                                TaxiReis.MaskAll();

                                            },
                                            error: function (data) {
                                                console.error("erro: ", data)
                                                if (data.responseJSON.motorista_existe == true) {
                                                    return modals.confirm({
                                                        title: "Motorista já cadastrado na fatura",
                                                        body: "Motorista já cadastrado em outra fatura nesse mesmo horario,você realmente deseja inserir a fatura com esse motorista?",
                                                        textTrue: "Sim",
                                                        textFalse: "Não",
                                                        onSubmit: function (e) {
                                                            form_data['verificar_motorista'] = false;
                                                            if (e) {
                                                                $.ajax({
                                                                    url: 'api/v2/' + type + '/' + id,
                                                                    type: 'patch',
                                                                    data: JSON.stringify(form_data),
                                                                    contentType: 'application/json',
                                                                    processData: false,
                                                                    dataType: 'json',
                                                                    success: function (data) {
                                                                        console.info(data);

                                                                        $(".modal-backdrop").remove();
                                                                        $('body').removeClass('modal-open');
                                                                        if (data.error === true) {
                                                                            toastr.error(data.message);
                                                                        } else {
                                                                            toastr.success(data.message);
                                                                        }
                                                                        $(modal.element).modal('hide');
                                                                        $("#crud-table").DataTable().ajax.reload();
                                                                        $('#itinerarios_table').DataTable().ajax.reload();
                                                                        TaxiReis.MaskAll();

                                                                    },
                                                                    error: function (data) {
                                                                        if (response.message === "Motorista já cadastrado") {

                                                                            return modals.confirm({
                                                                                title: "Motorista já cadastrado na fatura",
                                                                                body: "Motorista já cadastrado em outra fatura nesse mesmo horario,você realmente deseja inserir a fatura com esse motorista?",
                                                                                textTrue: "Sim",
                                                                                textFalse: "Não",
                                                                                onSubmit: function (e) {
                                                                                    if (e) {
                                                                                        $.ajax({
                                                                                            type: "POST",
                                                                                            url: "/api/v2/faturas/cancelar/" + cur_ir,
                                                                                            success: function (data) {
                                                                                                if (!data.error)
                                                                                                    toastr["success"]("Status alterado com sucesso");
                                                                                                else
                                                                                                    toastr["error"]("Erro: " + data);
                                                                                                $.each(window.LaravelDataTables, function (k, v) {
                                                                                                    v.ajax.reload();
                                                                                                })
                                                                                            },
                                                                                            error: function (data) {
                                                                                                toastr["error"]("Erro: " + JSON.stringify(data));
                                                                                            }

                                                                                        });
                                                                                    }
                                                                                },
                                                                                onDispose: function () {

                                                                                },
                                                                            });

                                                                        }
                                                                        const response = data.responseJSON;
                                                                        if (typeof response.errors !== 'undefined' && Object.keys(response.errors).length > 0) {
                                                                            $.each(response.errors, function (k, v) {
                                                                                if (v.length > 0) {
                                                                                    $.each(v, function (key, value) {
                                                                                        toastr.error(value);
                                                                                    })
                                                                                } else {
                                                                                    toastr.error(v)
                                                                                }
                                                                            })
                                                                        } else {
                                                                            if (typeof response.message !== 'undefined') {
                                                                                toastr.error(response.message)
                                                                            } else {
                                                                                toastr.error(response)
                                                                            }
                                                                        }
                                                                        TaxiReis.MaskAll();
                                                                    }
                                                                });
                                                            }
                                                        },
                                                        onDispose: function () {

                                                        },
                                                    });
                                                }
                                                const response = data.responseJSON;
                                                if (typeof response.errors !== 'undefined' && Object.keys(response.errors).length > 0) {
                                                    $.each(response.errors, function (k, v) {
                                                        if (v.length > 0) {
                                                            $.each(v, function (key, value) {
                                                                toastr.error(value);
                                                            })
                                                        } else {
                                                            toastr.error(v)
                                                        }
                                                    })
                                                } else {
                                                    if (typeof response.message !== 'undefined') {
                                                        toastr.error(response.message)
                                                    } else {
                                                        toastr.error(response)
                                                    }
                                                }
                                                TaxiReis.MaskAll();
                                            }
                                        });
                                    }

                                    sendData()
                                })
                                let inputs = $(modal.element).find("input[type=time]");
                                $.each(inputs, function (k, v) {
                                    let in_vals = $(v).val().split(":")
                                    if (in_vals.length > 2) {
                                        let in_values = []
                                        for (let i = 0; i < in_vals.length; i++) {
                                            if (i < 2) {
                                                in_values.push(in_vals[i])
                                            }
                                        }
                                        $(v).val(in_values.join(":"))
                                        console.info(in_values)
                                    }

                                })
                                switch (type) {
                                    case "solicitacoes":
                                        // jsonEditorInit(modal.element, $(modal.element).find("#passageiros").val())
                                        $(modal.element).find("#json_table table").passageiros_table(data.data.passageiros, modal, $(modal).find("#cod_itinerario"), fatura, solicitacoes)
                                        $(modal.element).find("#faturas_table").DataTable({
                                            data: [{ "cod_fatura": this.id }],
                                            "columns": [
                                                {
                                                    "data": "id",
                                                    "name": "id",
                                                    "title": "Fatura",
                                                    "orderable": true,
                                                    "searchable": true,
                                                    "responsivePriority": 1
                                                },
                                            ],
                                            order: [
                                                [0, "desc"]
                                            ],
                                            responsive: true,
                                            autoWidth: false,
                                            dom: "<\"toolbar\">Blfrtip",
                                        })
                                        break;
                                    case "faturas":
                                        calcularTotal();
                                        $(modal.element).find("#km_extras,#horas_paradas,#despesas_adicionais,#estacionamento,#pedagio,#barcas").on('change', function () {
                                            calcularTotal();
                                        });
                                        const formatter = new Intl.NumberFormat('pt-BR', {
                                            style: 'currency',
                                            currency: 'BRL',
                                        });

                                        $("#horas_paradas").change(function () {
                                            let tarifas = $("#tarifas_table").dataTable().api().data().toArray();
                                            let taxa_van = null;
                                            let taxa_carro = null;
                                            let v_ = this.value
                                            if (typeof tarifas === "undefined" || tarifas === null || tarifas.length === 0) {
                                                taxa_carro = taxa_carro = v_ * 1
                                            }
                                            $.each(tarifas, function (k, v) {
                                                if (v.cod_tipo_veiculo === 6) {
                                                    taxa_carro = v_ * v.valor_hora_parada;
                                                } else if (v.cod_tipo_veiculo === 8) {
                                                    taxa_van = v_ * v.valor_hora_parada;
                                                }
                                            })

                                            let v_carro = formatter.format(taxa_carro);
                                            let v_van = formatter.format(taxa_van)
                                            if (taxa_carro === null) {
                                                this.removeAttribute("carro")
                                            } else {
                                                this.setAttribute("carro", taxa_carro)
                                            }
                                            if (taxa_van === null) {
                                                this.removeAttribute("van")
                                            } else {
                                                this.setAttribute("van", taxa_van)
                                            }

                                            let ht = "";
                                            if (taxa_van != null && taxa_carro != null) {
                                                ht = "<small orignal_val='" + taxa_carro + "' id='taxa_carro'><i class=\"mdi mdi-car-side\" style=\"padding-right: 5px;\"></i>" + v_carro + "</small>"
                                                ht = ht + "<small orignal_val='" + taxa_van + "' id='taxa_van'><i class=\"mdi mdi-van-passenger\" style=\"padding-right: 5px;\"></i>  " + v_van + "  </small>"
                                            } else {
                                                if (taxa_carro != null) {
                                                    ht = "<small orignal_val='" + taxa_carro + "' id='taxa_van'><i class=\"mdi mdi-car-side\" style=\"padding-right: 5px;\"></i>  " + v_carro + "  </small>"
                                                }
                                                if (taxa_van != null) {
                                                    ht = "<small orignal_val='" + taxa_van + "' id='taxa_van'><i class=\"mdi mdi-van-passenger\" style=\"padding-right: 5px;\"></i> " + v_van + "  </small>"
                                                }
                                            }
                                            $("#horas_paradas_right").html(ht)
                                        })
                                        $("#km_extras").change(function () {
                                            let tarifas = $("#tarifas_table").dataTable().api().data().toArray();
                                            let taxa_van = null;
                                            let taxa_carro = null;
                                            let v_ = this.value
                                            if (typeof tarifas === "undefined" || tarifas === null || tarifas.length === 0) {
                                                taxa_carro = taxa_carro = v_ * 1
                                            }
                                            console.info(tarifas)
                                            $.each(tarifas, function (k, v) {
                                                if (v.cod_tipo_veiculo === 6) {
                                                    taxa_carro = v_ * v.valor_km_excedido;
                                                } else if (v.cod_tipo_veiculo === 8) {
                                                    taxa_van = v_ * v.valor_km_excedido;
                                                }
                                            })
                                            if (taxa_carro === null) {
                                                this.removeAttribute("carro")
                                            } else {
                                                this.setAttribute("carro", taxa_carro)
                                            }
                                            if (taxa_van === null) {
                                                this.removeAttribute("van")
                                            } else {
                                                this.setAttribute("van", taxa_van)
                                            }
                                            let v_carro = formatter.format(taxa_carro);
                                            let v_van = formatter.format(taxa_van)
                                            let ht = "";
                                            if (taxa_van != null && taxa_carro != null) {
                                                ht = "<small><i class=\"mdi mdi-car-side\" style=\"padding-right: 5px;\"></i>  " + v_carro + "  </small>"
                                                ht = ht + "<small><i class=\"mdi mdi-van-passenger\" style=\"padding-right: 5px;\"></i>  " + v_van + "  </small>"
                                            } else {
                                                if (taxa_carro != null) {
                                                    ht = "<small><i class=\"mdi mdi-car-side\" style=\"padding-right: 5px;\"></i>  " + v_carro + "  </small>"
                                                }
                                                if (taxa_van != null) {
                                                    ht = "<small><i class=\"mdi mdi-van-passenger\" style=\"padding-right: 5px;\"></i>  " + v_van + "  </small>"
                                                }
                                            }
                                            $("#km_extras_right").html(ht)
                                        })

                                        break;
                                }

                            }
                        })
                        TaxiReis.MaskAll();
                    },

                    complete: function () {
                        $.LoadingOverlay("hide");
                    }
                });
        },
        MaskAll: function () {
            const behavior = function (val) {
                return val.replace(/\D/g, '').length > 9 ? '(00) 00000-0000' : '(00) 0000-0000';
            },
                options = {
                    onKeyPress: function (val, e, field, options) {
                        field.mask(behavior.apply({}, arguments), options);
                    }
                };


            $('#v_fixo').mask('#.##0,00', {
                reverse: true
            });
            $('#per_comissao').mask('##0,00%', {
                reverse: true
            });
            $(['#telefone', '#tel_res']).mask('(00) 0000-0000');
            $(['#tel_cel', '#celular']).mask('(00) 00000-0000');
            $('#fone_contato').mask(behavior, options);
            $('#num_habilitacao').mask('000000000-00');
            $('#cep').mask('00000-000');
            $('#cpnj').mask('00.000.000/0000-00');
        },
        UnMaskAll: function () {
            $("input").unmask();
        }
    }

}(jQuery))
